import { AnalyticsDateRanges } from "../Container";
import { BarDatum, ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useState } from "react";

import { useTranslation } from "react-i18next";
import i18n from "src/i18n";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { IconButton } from "src/shared/components/IconButton/IconButton";

import "./styles.scss";

type WalletTransactionsBarChartProps = {
    data: readonly BarDatum[] | null;
    keys: readonly string[];
    indexBy: string;
    colors: string[];
    header: { title: string; icon: IconListType };
    selectedDateRange?: AnalyticsDateRanges;
};

export const WalletTransactionsBarChart = ({
    data,
    keys,
    indexBy,
    colors,
    header,
    selectedDateRange,
}: WalletTransactionsBarChartProps) => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(0);

    const defaultItemsPerPage = 5;
    const slicedData = data?.slice(currentPage * defaultItemsPerPage, (currentPage + 1) * defaultItemsPerPage) || [];
    const totalPages = data !== null ? Math.ceil(data?.length / defaultItemsPerPage) : 0;

    const handleClickButton = (direction: "prev" | "next") => () => {
        if (direction === "prev") {
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
        } else if (direction === "next") {
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
        }
    };

    const isPrevDisabled = currentPage === 0;
    const isNextDisabled = currentPage >= totalPages - 1;

    return (
        <div className='wallet-transactions-bar-chart-container'>
            <div className='wallet-transactions-bar-chart-header'>
                <div className='text-header'>
                    <Avatar icon={header.icon} size='md' />
                    <Typography message={header.title} className='fw-bold' size='sm' />
                </div>
                <div className='buttons-container'>
                    <IconButton
                        handleOnClick={handleClickButton("prev")}
                        disabled={isPrevDisabled}
                        iconProps={{ name: "arrow-left" }}
                    />
                    <IconButton
                        handleOnClick={handleClickButton("next")}
                        disabled={isNextDisabled}
                        iconProps={{ name: "arrow-right" }}
                    />
                </div>
            </div>
            <div className='wallet-transactions-bar-chart-content'>
                {data === null ? (
                    <Typography message={t("Analytics.no-data")} />
                ) : (
                    <ResponsiveBar
                        colors={colors}
                        groupMode='grouped'
                        data={slicedData as readonly BarDatum[]}
                        enableLabel={false}
                        keys={keys}
                        indexBy={indexBy}
                        margin={{ top: 50, right: 30, bottom: 70, left: 100 }}
                        padding={0.3}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", 1.6]],
                        }}
                        minValue={0}
                        theme={{
                            text: {
                                fontSize: 14,
                                fontWeight: "bold",
                            },
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: "color",
                            modifiers: [["darker", 1.6]],
                        }}
                        animate={true}
                        label={(d) => `${formatMoneyToString({ amount: Number(d.value) })}`}
                        legends={[
                            {
                                dataFrom: "keys",
                                anchor: "bottom-left",
                                direction: "row",
                                justify: false,
                                translateX: 0,
                                translateY: 60,
                                itemsSpacing: 100,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 20,
                            },
                        ]}
                        role='application'
                        ariaLabel='Nivo bar chart demo'
                        barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`}
                        tooltip={({ data }) => (
                            <div className='wallet-transactions-bar-chart-hover-window'>
                                <div className='item'>
                                    <Typography message={t("CommonUse.date") + ": "} />
                                    {selectedDateRange?.value !== "today" ? (
                                        selectedDateRange?.value !== "threeLastMonth" ? (
                                            <Typography
                                                message={moment(data.date).format("DD MMMM YYYY")}
                                                className='fw-bold'
                                            />
                                        ) : (
                                            <Typography
                                                message={moment(data.date, "DD MMM YYYY").format("MMMM YYYY")}
                                                className='fw-bold'
                                            />
                                        )
                                    ) : (
                                        <Typography message={moment().format("DD MMMM YYYY")} className='fw-bold' />
                                    )}
                                </div>
                                <div className='item'>
                                    <Typography message={t("Analytics.wallet-transactions-hover-in")} />
                                    <Typography
                                        message={formatMoneyToString({
                                            amount: data[i18n.t("Analytics.transactions-in")],
                                        })}
                                        className='fw-bold'
                                    />
                                </div>
                                <div className='item'>
                                    <Typography message={t("Analytics.wallet-transactions-moeny-out")} />
                                    <Typography
                                        message={formatMoneyToString({
                                            amount: data[i18n.t("Analytics.transactions-out")],
                                        })}
                                        className='fw-bold'
                                    />
                                </div>
                            </div>
                        )}
                    />
                )}
            </div>
        </div>
    );
};
