import { WalletSelectionDrawer } from "../WalletSelectionDrawer/Drawer";
import { BulkTransfertFileForm } from "./FileForm";
import { BulkTransfertInformations, BulkTransfertInformationsForm } from "./InformationsForm";
import { useLocation, useNavigate } from "react-router-dom";
import { createTask } from "src/services/tasks/operations";
import { WalletApi } from "src/services/wallets/types";
import { CompanyStatus } from "src/shared/models/UserCompany";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { getTaskToEdit } from "src/modules/tasks/selectors";
import { receiveTaskToEdit } from "src/modules/tasks/slice";
import { getSendWallets } from "src/modules/wallets/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { DrawerInput } from "src/shared/atoms/Inputs/Drawer/Input";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";
import { BlockedAccountAlert } from "src/shared/components/BlockedAccountAlert/BlockedAccountAlert";
import { KybAlert } from "src/shared/components/KybAlert/KybAlert";

import "./styles.scss";

export enum BULK_TRANSFERT_STEPS {
    DEBIT_SELECT,
    INFORMATIONS,
    FILE,
}

type LocationState = {
    preselectedWalletId?: string;
};

export const BulkTransfertPanel = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const wallets = useAppSelector((state) => getSendWallets(state));
    const authUser = useAppSelector((state) => getAuthUser(state));
    const taskId = useAppSelector((state) => getTaskToEdit(state)?.id);

    const providedState = useLocation().state as LocationState;
    const isValidProvidedState = !!wallets.find((wallet) => wallet.id === Number(providedState?.preselectedWalletId));

    const isBlockedAccount = !!(authUser && authUser.Company?.status !== CompanyStatus.BLOCK_0);

    const [currentStep, setCurrentStep] = useState<BULK_TRANSFERT_STEPS>(BULK_TRANSFERT_STEPS.DEBIT_SELECT);
    const [selectedDebitAccount, setSelectedDebitAccount] = useState<WalletApi | null>(null);
    const [isDebitDrawerOpened, setIsDebitDrawerOpened] = useState(false);
    const [informations, setInformations] = useState<BulkTransfertInformations | null>(null);
    const [isInformationFormValid, setIsInformationFormValid] = useState(false);

    const handleOpenAccordion = (step: BULK_TRANSFERT_STEPS) => () => {
        setCurrentStep(step);
    };

    const checkAccordionDebitOpening = () => {
        const isValidated = Boolean(selectedDebitAccount);
        if (isValidated) {
            setCurrentStep(BULK_TRANSFERT_STEPS.INFORMATIONS);
        }
    };

    const checkAccordionInformationsOpening = () => {
        const isValidated = Boolean(selectedDebitAccount);
        if (isValidated) {
            setCurrentStep(BULK_TRANSFERT_STEPS.FILE);
            handleCreateTask();
        }
    };

    const handleOpenDebitDrawer = () => {
        setIsDebitDrawerOpened(!isDebitDrawerOpened);
    };

    const handleSelectDebitAccount = (account: WalletApi) => {
        setSelectedDebitAccount(account);
        setIsDebitDrawerOpened(false);
    };

    const handleChangeInformationsForm = (informations: BulkTransfertInformations) => {
        setInformations(informations);
    };

    const handleRedirectWithoutFile = () => {
        navigate(`/tasks/${taskId}/edit`);
    };

    const handleCreateTask = () => {
        if (isInformationFormValid && selectedDebitAccount && informations) {
            createTask({
                walletId: selectedDebitAccount.id.toString(),
                title: informations.transfertName,
                description: informations.description,
                includeReceiverFees: informations.selectedFees,
                smsTemplate: informations.smsTemplate ? informations.smsTemplate : undefined,
            }).then((response) => {
                dispatch(receiveTaskToEdit({ task: response }));
            });
        }
    };

    useEffect(() => {
        if (providedState?.preselectedWalletId && isValidProvidedState) {
            setSelectedDebitAccount(
                wallets.find((wallet) => wallet.id === Number(providedState.preselectedWalletId)) ?? null
            );
            setCurrentStep(BULK_TRANSFERT_STEPS.INFORMATIONS);
        } else {
            handleOpenDebitDrawer();
        }
    }, [providedState?.preselectedWalletId]);

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter") {
                switch (currentStep) {
                    case BULK_TRANSFERT_STEPS.DEBIT_SELECT:
                        if (selectedDebitAccount) {
                            checkAccordionDebitOpening();
                        } else {
                            handleOpenDebitDrawer();
                        }
                        break;
                    case BULK_TRANSFERT_STEPS.INFORMATIONS:
                        if (isInformationFormValid) {
                            checkAccordionInformationsOpening();
                        }
                        break;
                    case BULK_TRANSFERT_STEPS.FILE:
                        handleRedirectWithoutFile();
                        break;
                    default:
                        break;
                }
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    }, [
        currentStep,
        selectedDebitAccount,
        isInformationFormValid,
        checkAccordionDebitOpening,
        checkAccordionInformationsOpening,
    ]);

    return (
        <>
            <div className='bulk-transfert-panel-container'>
                <BlockedAccountAlert topBottom />
                <KybAlert topBottom />
                <StepAccordion
                    title={t("TransfertPanel.choisissez-un-compte-a-debiter")}
                    step={BULK_TRANSFERT_STEPS.DEBIT_SELECT}
                    subTitle={
                        currentStep > BULK_TRANSFERT_STEPS.DEBIT_SELECT && selectedDebitAccount
                            ? selectedDebitAccount.label + " - " + selectedDebitAccount.balance + " FCFA"
                            : undefined
                    }
                    currentStep={currentStep}
                    onOpen={handleOpenAccordion(BULK_TRANSFERT_STEPS.DEBIT_SELECT)}
                    footer={
                        <Button
                            className='continue-button'
                            variant='primary'
                            label={t("CommonUse.continue")}
                            onClick={checkAccordionDebitOpening}
                            disabled={!selectedDebitAccount || isBlockedAccount}
                            testId='transfert-continue-button-debit'
                        />
                    }
                >
                    <DrawerInput
                        label={
                            !selectedDebitAccount
                                ? t<string>("TransfertPanel.choisissez-un-compte")
                                : selectedDebitAccount.label +
                                  " - " +
                                  formatMoneyToString({ amount: selectedDebitAccount.balance })
                        }
                        handleClickDrawerInput={handleOpenDebitDrawer}
                        icon='wallet'
                        testId='transfert-drawer-input-debit'
                        disabled={isBlockedAccount}
                    />
                </StepAccordion>
                <StepAccordion
                    title={t("BulkTransferPage.tranfer-infos")}
                    subTitle={
                        currentStep > BULK_TRANSFERT_STEPS.INFORMATIONS && informations
                            ? informations.transfertName
                            : undefined
                    }
                    step={BULK_TRANSFERT_STEPS.INFORMATIONS}
                    currentStep={currentStep}
                    onOpen={handleOpenAccordion(BULK_TRANSFERT_STEPS.INFORMATIONS)}
                    footer={
                        <Button
                            className='continue-button'
                            variant='primary'
                            label={t("CommonUse.continue")}
                            disabled={!isInformationFormValid}
                            onClick={checkAccordionInformationsOpening}
                            testId='transfert-continue-button-informations'
                        />
                    }
                >
                    <BulkTransfertInformationsForm
                        informations={informations}
                        onChangeInformationsForm={handleChangeInformationsForm}
                        setIsFormValid={(isValid: boolean) => setIsInformationFormValid(isValid)}
                    />
                </StepAccordion>
                <StepAccordion
                    title={t("BulkTransferPage.transfer-file")}
                    subTitle={t<string>("BulkTransferPage.import-with-or-without-file")}
                    step={BULK_TRANSFERT_STEPS.FILE}
                    currentStep={currentStep}
                    onOpen={handleOpenAccordion(BULK_TRANSFERT_STEPS.FILE)}
                    footer={
                        <Button
                            className='continue-button'
                            variant='primary'
                            label={t("Tasks.continue-without-file")}
                            icon='chevron-right'
                            position='right'
                            onClick={handleRedirectWithoutFile}
                            testId='transfert-continue-button-informations'
                        />
                    }
                >
                    <BulkTransfertFileForm />
                </StepAccordion>
            </div>
            <WalletSelectionDrawer
                handleSelectedWallet={handleSelectDebitAccount}
                isOpen={isDebitDrawerOpened}
                onClose={handleOpenDebitDrawer}
                walletType='SEND'
                disableZeroBalance
            />
        </>
    );
};
