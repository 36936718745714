import { Avatar } from "../../Avatar/Avatar";
import { Button } from "../../Buttons/Button";
import { IconListType, iconList } from "../../Icons/IconList";
import { StatusIcon } from "../../Icons/StatusIcons";
import { Typography } from "../../Typography/Typography";
import { AvatarDrawer, DrawerButton, StatusIconDrawer } from "../Drawer";

import { Fragment } from "react";

import { Menu, MenuProps } from "src/shared/components/Menu/Menu";

import "./styles.scss";

type DrawerHeaderProps = {
    title: string;
    image: IconListType | AvatarDrawer | StatusIconDrawer;
    subtitle?: string;
    optionalButtons?: DrawerButton[];
    menuProps?: MenuProps;
    onCloseDrawer: () => void;
};

export const DrawerHeader = ({
    title,
    image,
    subtitle,
    optionalButtons,
    menuProps,
    onCloseDrawer,
}: DrawerHeaderProps) => {
    const renderImage = () => {
        if (typeof image === "object") {
            if ("avatar" in image) {
                return <Avatar {...image} size='md' />;
            } else if ("status" in image) {
                return <StatusIcon status={image.status} withColor size='md' />;
            }
        } else if (typeof image === "string" && iconList.includes(image as IconListType)) {
            return <Avatar icon={image} />;
        }
        return null;
    };

    return (
        <div className='drawer-header-container' data-testid='drawer-header-container'>
            <div className='drawer-header-title-section'>
                <div className='title-section-image'>{renderImage()}</div>
                <div className='title-section-content'>
                    <Typography message={title} className='title' />
                    {subtitle && <Typography message={subtitle} />}
                </div>
            </div>
            <div className='drawer-header-buttons-section' data-testid='drawer-header-buttons-section'>
                {menuProps && <Menu {...menuProps} />}
                {optionalButtons &&
                    optionalButtons.map((button, index) => {
                        return (
                            <Fragment key={index}>
                                <Button
                                    icon={button.icon}
                                    size='sm'
                                    variant='tertiary'
                                    color='primary'
                                    label={button.label ? button.label : undefined}
                                    onClick={button.onClick}
                                    disabled={button.disabled}
                                />
                            </Fragment>
                        );
                    })}
                <Button variant='tertiary' color='primary' icon='x-mark-filled' onClick={onCloseDrawer} />
            </div>
        </div>
    );
};
