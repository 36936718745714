import { BillingPayBillFilters } from "./Filters";
import { BillingPayBillItem } from "./Item";
import { useLocation } from "react-router-dom";

import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { getCompanyFavoriteBillers } from "src/modules/authCompanies/thunkActions";
import {
    getBillingBillers,
    getBillingBillersCategoryFilter,
    getBillingBillersCurrentPage,
    getBillingBillersIsLoading,
    getBillingBillersPageSize,
    getBillingBillersQueryFilter,
    getBillingBillersSectorFilter,
    getBillingBillersTotalCount,
} from "src/modules/billing/billers/selectors";
import {
    setBillingBillerCurrentPage,
    setBillingBillerPageSize,
    setBillingBillerQueryFilter,
} from "src/modules/billing/billers/slice";
import { fetchBillingBillers } from "src/modules/billing/billers/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { Input } from "src/shared/atoms/Inputs/Input";
import { CopyInput } from "src/shared/components/Copy/Input";
import { Empty } from "src/shared/components/Empty/Empty";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { Table } from "src/shared/components/Table/Table";

import "./styles.scss";

type LocationState = {
    preselectedWalletId?: string;
};
const emptyListImg = require("src/shared/images/empty/empty-referral.png");

export const BillingPayBillPanel = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const providedState = useLocation().state as LocationState;

    const isLoading = useAppSelector((state) => getBillingBillersIsLoading(state));
    const query = useAppSelector((state) => getBillingBillersQueryFilter(state));
    const billers = useAppSelector((state) => getBillingBillers(state) ?? []);
    const totalCount = useAppSelector((state) => getBillingBillersTotalCount(state));
    const sectors = useAppSelector((state) => getBillingBillersSectorFilter(state));
    const categoryFilter = useAppSelector((state) => getBillingBillersCategoryFilter(state));
    const currentPage = useAppSelector((state) => getBillingBillersCurrentPage(state));
    const pageSize = useAppSelector((state) => getBillingBillersPageSize(state));
    const authUser = useAppSelector((state) => getAuthUser(state));

    const debouncedQuery = useDebounce(query, 700);

    useEffect(() => {
        dispatch(
            fetchBillingBillers({
                page: currentPage,
                pageSize: pageSize,
                query,
                sectors,
                categoryFilter,
            })
        );
    }, [dispatch, debouncedQuery, currentPage, pageSize]);

    useEffect(() => {
        dispatch(getCompanyFavoriteBillers());
    }, [dispatch]);

    const handleSetCurrentPage = (newCurrentPage: number) => {
        dispatch(setBillingBillerCurrentPage({ currentPage: newCurrentPage }));
    };

    const handleSetPageSize = (newPageSize: number) => {
        dispatch(setBillingBillerPageSize({ pageSize: newPageSize }));
    };

    const handleSetQueryFilter = (value: string) => {
        dispatch(setBillingBillerQueryFilter({ query: value }));
    };

    return (
        <div className='pay-bill-container'>
            <PageHeader>
                <div className='pay-bill-filters'>
                    <Input
                        placeholder={t("CommonUse.search")}
                        inputIcon='magnifying-glass'
                        onChange={handleSetQueryFilter}
                        value={query}
                        className='search-input'
                    />
                    <BillingPayBillFilters />
                </div>
            </PageHeader>
            <Table
                isLoading={isLoading}
                pagination={{
                    currentPage,
                    pageSize: pageSize,
                    totalCount,
                    onPageChange: handleSetCurrentPage,
                    onPageSizeChange: handleSetPageSize,
                }}
                header={[""]}
                empty={
                    <div className='empty-part'>
                        <Empty
                            image={emptyListImg}
                            title={t("BillingEmptyPaymentList.empty-title")}
                            description={t<string>("BillingEmptyPaymentList.empty-description")}
                        />
                        {authUser && (
                            <div className='referal-input-container'>
                                <CopyInput
                                    label={t<string>("Profile.invite-new-people")}
                                    value={authUser.Company?.reference ?? ""}
                                    copiedContent={t("Profile.copied-content", {
                                        referalCode: authUser.Company?.reference,
                                    })}
                                />
                            </div>
                        )}
                    </div>
                }
                rows={billers.map((biller) => (
                    <BillingPayBillItem
                        biller={biller}
                        key={`biller-${biller.reference}`}
                        preselectedWalletId={providedState?.preselectedWalletId}
                    />
                ))}
            />
        </div>
    );
};
