import { useNavigate } from "react-router-dom";
import { BillingBiller } from "src/services/billing/biller/type";
import { sectorIcon } from "src/shared/const/sectors";
import { countries } from "src/shared/utils/getCountryInformations";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { addBillerToFavorite, removeBillerFromFavorite } from "src/modules/authCompanies/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

type BillingPayBillItemProps = {
    biller: BillingBiller;
    preselectedWalletId?: string;
};

export function BillingPayBillItem({ biller, preselectedWalletId }: Readonly<BillingPayBillItemProps>) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const company = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state));

    const { reference, name, sector, avatar, country, description } = biller;

    const handleRedirectPayment = () => {
        navigate(`/billing/pay-bill/${reference.replace("#", "")}`, {
            state: { preselectedWalletId },
        });
    };

    const handleAddToFavorite = () => {
        setLoading(true);
        dispatch(addBillerToFavorite({ billerReference: biller.reference }))
            .unwrap()
            .finally(() => setLoading(false));
    };

    const handleRemoveFromFavorite = () => {
        setLoading(true);
        dispatch(removeBillerFromFavorite({ billerReference: biller.reference }))
            .unwrap()
            .finally(() => setLoading(false));
    };

    const isFavorite = company?.favoriteBillers?.some(
        (billerReference) => billerReference.Biller.reference === biller.reference
    );
    const canAddAsFavorite = !!biller.reference.startsWith("JLA-");

    return (
        <tr>
            <td>
                <div className='biller-table-item-container'>
                    <div className='biller-table-item'>
                        {avatar ? (
                            <Avatar size='lg' avatar={avatar} />
                        ) : (
                            <div className='avatar'>
                                <Icon name={sectorIcon[biller.sector] ?? "kiosk"} color='lilas' />
                            </div>
                        )}
                        <div className='biller-table-item-description'>
                            <div className='reference-name-container'>
                                {/bill-payment-.*/.test(reference) ? (
                                    <></>
                                ) : (
                                    <>
                                        <Typography message={reference} className='fw-bold color-neutral-500' />
                                        <div className='dot-container'>
                                            <div className='dot' />
                                        </div>
                                    </>
                                )}

                                <Typography message={name} className='fw-bold' />
                            </div>
                            <div className='type-country-container'>
                                <div className='flex'>
                                    <Icon name='briefcase' status='primary' />
                                    <Typography message={t(`Bills.sector-${sector}`)} className='fw-bold tag' />
                                </div>
                                <div className='flex'>
                                    <Icon name='globe' status='primary' />
                                    <Typography message={t(countries[country]?.name ?? "")} className='fw-bold' />
                                </div>
                            </div>
                            <div className='description-container'>
                                <Icon name='information-circle' status='primary' />
                                <Typography message={description ?? "-"} />
                            </div>
                            <div></div>
                        </div>
                    </div>

                    {canAddAsFavorite && (
                        <Button
                            disabled={loading}
                            onClick={isFavorite ? handleRemoveFromFavorite : handleAddToFavorite}
                            label={t(isFavorite ? "Bills.delete-favorite" : "Bills.add-favorite")}
                            icon={isFavorite ? "heart-filled" : "heart"}
                            className='mr-2 nowrap'
                            variant='tertiary'
                            position='right'
                        />
                    )}
                    <Button
                        onClick={handleRedirectPayment}
                        label={t("Billing.pay-bill")}
                        icon='receipt-accepted'
                        className='nowrap'
                        position='right'
                    />
                </div>
            </td>
        </tr>
    );
}
