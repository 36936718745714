import { CompanyRole, CountriesTag } from "src/shared/models/UserCompany";

import { ReactNode } from "react";

import { TaskButton } from "src/components/Tasks/Button";
import { TopUpDrawerButton } from "src/components/TopUp/Button";
import { TransferDrawerButton } from "src/components/TransfertDrawer/Button";
import { IconListType } from "src/shared/atoms/Icons/IconList";

type SubLink = {
    name: string;
    link?: string;
    component?: ReactNode;
    icon: IconListType;
    roles: CompanyRole[];
    disallowedCountries?: CountriesTag[];
    badge?: string;
};

export type NavLinksType = {
    name: string;
    icon: IconListType;
    roles: CompanyRole[];
    disallowedCountries?: CountriesTag[];
    isAdmin: boolean;
    subLinks: SubLink[];
    badge?: string;
};

export const navLinks: NavLinksType[] = [
    {
        name: "NavLinks.accueil",
        icon: "home",
        roles: [CompanyRole.ADMIN, CompanyRole.USER, CompanyRole.READ],
        isAdmin: false,
        subLinks: [
            {
                name: "Dashboard.dashboard",
                link: "/dashboard",
                icon: "squares",
                roles: [CompanyRole.ADMIN, CompanyRole.USER, CompanyRole.READ],
            },
        ],
    },
    {
        name: "NavLinks.operations",
        icon: "bills",
        roles: [CompanyRole.ADMIN, CompanyRole.USER],
        isAdmin: false,
        subLinks: [
            {
                name: "NavLinks.rechargement",
                component: <TopUpDrawerButton />,
                icon: "plus-circle",
                roles: [CompanyRole.ADMIN, CompanyRole.USER],
            },
            {
                name: "CommonUse.transfer",
                component: <TransferDrawerButton />,
                icon: "arrows-right-left",
                roles: [CompanyRole.ADMIN, CompanyRole.USER],
            },
            {
                name: "Billing.pay-bill",
                link: "/billing/pay-bill",
                icon: "receipt-accepted",
                roles: [CompanyRole.ADMIN, CompanyRole.USER],
            },
            {
                name: "Tasks.my-tasks",
                component: <TaskButton />,
                icon: "task",
                roles: [CompanyRole.ADMIN, CompanyRole.USER],
            },
        ],
    },
    {
        name: "Loans.loans",
        icon: "get-paid",
        roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY, CompanyRole.USER, CompanyRole.READ],
        disallowedCountries: ["BEN"],
        isAdmin: false,
        subLinks: [
            {
                name: "Loans.my-loans",
                link: "/loan/my-loans",
                icon: "home",
                roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY, CompanyRole.USER, CompanyRole.READ],
            },
        ],
    },
    {
        name: "Export.Exports",
        icon: "calculator",
        roles: [CompanyRole.ADMIN, CompanyRole.USER, CompanyRole.READ],
        isAdmin: false,
        subLinks: [
            {
                name: "Export.my-exports",
                link: "/export",
                icon: "arrow-down-on-square",
                roles: [CompanyRole.ADMIN, CompanyRole.USER, CompanyRole.READ],
            },
            {
                name: "Export.export-models",
                link: "/export/models",
                icon: "document",
                roles: [CompanyRole.ADMIN, CompanyRole.USER, CompanyRole.READ],
            },
        ],
    },
    {
        name: "CommonUse.administration",
        icon: "gear",
        roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
        isAdmin: true,
        subLinks: [
            {
                name: "Dashboard.dashboard",
                link: "/admin",
                icon: "trending-up",
                roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
            },
            {
                name: "Wallets.my-wallets",
                link: "/admin/wallets",
                icon: "wallet",
                roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
            },
            {
                name: "Users.my-team",
                link: "/admin/users",
                icon: "briefcase",
                roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
            },
            {
                name: "CommonUse.my-company",
                link: "/billing/get-paid",
                icon: "office",
                roles: [CompanyRole.ADMIN],
            },
            {
                name: "Beneficiaries.my-beneficiaries",
                link: "/admin/beneficiaries",
                icon: "user-group",
                roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
            },
            {
                name: "CommonUse.my-repo",
                link: "/admin/documents",
                icon: "document",
                roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
            },
            {
                name: "Expense-policy.title",
                link: "/admin/expense-policy",
                icon: "adjustments-horizontal",
                roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
            },
            {
                name: "AdminActivity.title",
                link: "/admin/activity-logs",
                icon: "clock",
                roles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
            },
        ],
    },
];
