import { ApiErrors } from "./types";

export const APP_VERSION = "1.1.0";
export const SECRET_TOKEN = process.env.REACT_APP_SECRET as string;
export const API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_GTM_ID = "GTM-TC8B3ZB7";
export const JULAYA_APP_URL = "https://app.julaya.co/";
export const DEMO_WARNING = process.env.REACT_APP_ENV !== "live" && process.env.REACT_APP_ENV !== "local";

const errorTranslationKey = "Api-errors";

const assignTranslationToErrorKey = (errorKey: ApiErrors) => {
    return `${errorTranslationKey}.${errorKey.toLowerCase().replaceAll("_", "-")}`;
};

const ERRORS: ApiErrors[] = [
    "UNAUTHORIZED",
    "ERROR_LOGIN_USER_NOT_FOUND",
    "ERROR_LOGIN_EMAIL_NOT_VERIFIED",
    "USER_IS_COLLECTOR_ONLY",
    "ERROR_LOGIN_USER_PWD_TOO_MANY_ATTEMPTS",
    "ERROR_LOGIN_USER_PWD_INCORRECT",
    "ERROR_LOGIN_USER_NOT_ACTIVE",
    "ERROR_FORGOT_PWD_USER_NOT_FOUND",
    "ERROR_FORGOT_PWD_USER_PWD_TOO_MANY_ATTEMPTS",
    "ERROR_FORGOT_PWD_USER_NOT_ACTIVE",
    "ERROR_USER_ID_NEEDED",
    "TASK_ALREADY_SENT",
    "REASON_REQUIRED",
    "TASK_ALREADY_CANCELLED",
    "TASK_CANCELLATION_FAILED_INVALID_STATUS",
    "TASK_ID_REQUIRED",
    "USER_2FA_ALREADY_ACTIVE",
    "SOME_ERROR_OCCURRED_RETRY",
    "USER_PHONE_INVALID",
    "COMPANY_PHONE_INVALID",
    "ERROR_READER_CANNOT_TOPUP",
    "ERROR_READER_CANNOT_INIT",
    "ERROR_READER_CANNOT_SEND",
    "USER_ARCHIVED",
    "USER_ALREADY_MEMBER",
    "AT_LEAST_ONE_ROLE_IS_REQUIRED",
    "COLLECTOR_PHONE_ALREADY_EXISTS",
    "USER_EMAIL_ALREADY_EXISTS",
    "USER_PHONE_ALREADY_EXISTS",
    "ERROR_USER_ARCHIVE_USER_NOT_FOUND",
    "ERROR_USER_NOT_FOUND",
    "NO_WALLET_FOUND",
    "COMMENT_TRANSACTION_WALLET_NOT_FOUND",
    "GET_PAN_WALLET_NOT_FOUND",
    "INVALID_ASSIGNEE_ADMIN_ONLY",
    "ERROR_CREATE_WALLET_ACCOUNT_NOT_FOUND",
    "ERROR_EDIT_WALLET_NOT_FOUND",
    "DESTINATION_WALLET_NOT_FOUND",
    "ERROR_CURRENT_COMPANY_ID_NOT_FOUND",
    "ACCOUNT_NOT_FOUND",
    "WALLET_NOT_FOUND",
    "TASK_NOT_FOUND",
    "INVALID_APP_VERSION",
    "ERROR_WALLET_NEEDED",
    "ERROR_SIGN_NO_ITEMS",
    "ERROR_SIGN_ITEMS_WITH_ERROR",
    "ERROR_SIGN_TASK_WITH_ERROR",
    "KYC_STATUS_CANNOT_BE_SET",
    "USER_NOT_ATTACHED_TO_COMPANY",
    "INVALID_SECRET_CODE_1",
    "INVALID_SECRET_CODE",
    "USER_ALREADY_EXISTS",
    "INVALID_PARAMS",
    "ERROR_RESET_PWD",
    "ERROR_TASK_RETRY_TASK_CANCELLED",
    "ERROR_TASK_RETRY_NO_TRANSACTION_FAILED",
    "TASK_ALREADY_SIGNED_BY_THIS_USER",
    "TASK_NOT_SENT",
    "USER_EMAIL_IS_REQUIRED",
    "USER_DATE_OF_BIRTH_IS_REQUIRED",
    "TASK_UUID_ALREADY_EXISTS",
    "SequelizeUniqueConstraintError", // TODO: wording
    "USER_2FA_CODE_INVALID",
    "WAVE_USER_NOT_FOUND",
    "INVALID_PHONE_NUMBER",
    "WALLET_HAS_TRANSACTIONS_PENDING",
    "BATCH_CHECKS_MAX_CASHIN",
    "SIMILAR_TRANSACTION_ALREADY_EXISTS",
    "EXPENSE_POLICY_FOR_PAIR_USER_WALLET_ALREADY_EXISTS",
    "RECEIVER_ACCOUNT_NOT_FOUND",
    "LOAN_OVERPAY",
    "BATCH_CHECKS_NOT_ENOUGHT_MONEY",
    "EXPEPSE_POLICY_NOT_FOUND",
    "INVALID_WALLET_IDS",
    "INVALID_USER_IDS",
    "EXPENSE_POLICY_EXCEEDED",
    "EXPENSE_POLICY_IS_USED_NOT_FOR_BENEFICIARY",
    "EXPENSE_POLICY_RULE_FOR_THE_WALLET_ALREADY_CREATED",
    "EXPENSE_POLICY_FOR_PAIR_USER_WALLET_ALREADY_EXISTS",
    "EXPENSE_POLICY_USELESS_RULE",
    "INVALID_EXPIRY_DATE",
    "EXPIRY_DATE_MUST_BE_LESS_THAN_OR_EQUAL_TO_2_YEARS_FROM_NOW",
    "AGL_ERROR_INVOICE_NOT_FOUND",
    "AGL_ERROR",
    "SERVICE_HELPERS_SERVICE_JULAYA_RECEIVER_NOT_FOUND",
    "INVALID_BILL_ID",
    "ERROR_TFA_IS_NOT_VALID",
    "TRANSACTION_CHECKS_RECEIVER_NAME_REQUIRED",
    "TRANSACTION_INVALID_IBAN",
    "MUST_HAVE_ONE_ADMIN",
    "DEFAULT_WALLET_CANNOT_BE_ARCHIVED",
    "DUPLICATE_EXTERNAL_REFERENCE",
    "EXTERNAL_REFERENCE_ALREADY_EXISTS",
    "SEND_ONLY_TO_BENEFICIARY",
    "ERROR_OTP_EXPIRED",
];

export const ERROR_LIST = ERRORS.reduce<{ [errorKey: string]: string }>((res, errorKey) => {
    res[errorKey] = assignTranslationToErrorKey(errorKey);
    return res;
}, {});
