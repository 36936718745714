import { useNavigate } from "react-router-dom";
import { WalletApi } from "src/services/wallets/types";
import { CompanyRole } from "src/shared/models/UserCompany";
import { DrawerContext } from "src/wrapper/context";

import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserLoading } from "src/modules/auth/selectors";
import { getJulayaWallets, getWalletsLoading } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { CreateExportDrawer } from "src/components/Export/Drawers/Export/CreateDrawer";
import { CreateTemplateBodyDrawer } from "src/components/Export/Drawers/Templates/Body";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

enum ActionSlugs {
    TOPUP = "topup",
    RAPID_TRANSFER = "rapid-transfer",
    BULK_TRANSFER = "bulk-transfer",
    PAY_BILLS = "pay-bills",
    MANAGE_ACCOUNT = "manage-account",
    EXPORT = "export",
    EXPORT_TEMPLATES = "export-templates",
}

type Action = {
    slug: ActionSlugs;
    label: string;
    icon: IconListType;
};

const adminActions: Action[] = [
    { slug: ActionSlugs.TOPUP, label: "CommonUse.appro", icon: "plus-circle" },
    { slug: ActionSlugs.RAPID_TRANSFER, label: "NavLinks.transfert-rapide", icon: "arrows-right-left" },
    { slug: ActionSlugs.BULK_TRANSFER, label: "CommonUse.add-task", icon: "bulk" },
    { slug: ActionSlugs.PAY_BILLS, label: "Billing.pay-bill", icon: "receipt-accepted" },
    { slug: ActionSlugs.MANAGE_ACCOUNT, label: "Dashboard.admin-your-acc", icon: "gear" },
];

const userActions: Action[] = [
    { slug: ActionSlugs.TOPUP, label: "CommonUse.appro", icon: "plus-circle" },
    { slug: ActionSlugs.RAPID_TRANSFER, label: "NavLinks.transfert-rapide", icon: "arrows-right-left" },
    { slug: ActionSlugs.BULK_TRANSFER, label: "CommonUse.add-task", icon: "bulk" },
    { slug: ActionSlugs.PAY_BILLS, label: "Billing.pay-bill", icon: "receipt-accepted" },
];

const readActions: Action[] = [
    { slug: ActionSlugs.EXPORT, label: "Export.export", icon: "arrow-down-on-square" },
    { slug: ActionSlugs.EXPORT_TEMPLATES, label: "Export.schedule-your-exp", icon: "clock" },
];

type QuickActionsProps = {
    currentWallet: WalletApi | null;
};

export const QuickActions = ({ currentWallet }: QuickActionsProps) => {
    const { t } = useTranslation();
    const { setOpen, setProvidedState } = useContext(DrawerContext);
    const navigate = useNavigate();

    const user = useAppSelector(getAuthUser);
    const isLoadingUser = useAppSelector((state) => getAuthUserLoading(state));
    const walletsLoading = useAppSelector((state) => getWalletsLoading(state));

    const wallets = useAppSelector((state) => getJulayaWallets(state));

    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [createTemplateDrawerOpen, setCreateTemplateDrawerOpen] = useState(false);

    const handleOpenCreateDrawer = () => {
        setCreateDrawerOpen(!createDrawerOpen);
    };

    const handleOpenCreateTemplateDrawer = () => {
        setCreateTemplateDrawerOpen(!createTemplateDrawerOpen);
    };

    const computeWalletsIds = Object.values(wallets).map((wallet) => wallet.id);

    const getActions = () => {
        switch (user?.role) {
            case CompanyRole.ADMIN:
                return adminActions;
            case CompanyRole.USER:
                return userActions;
            case CompanyRole.READ:
                return readActions;
            default:
                return [];
        }
    };

    const actions = getActions();

    const handleRedirect = (slug: ActionSlugs) => () => {
        if (walletsLoading) {
            return;
        }

        switch (slug) {
            case ActionSlugs.TOPUP:
                setOpen("topUp");
                if (currentWallet) {
                    setProvidedState({ walletId: currentWallet.id.toString(), sendStateOnRedirection: true });
                }
                break;
            case ActionSlugs.RAPID_TRANSFER:
                navigate("/transfert/rapid-transfert", {
                    state: {
                        preselectedWalletId: currentWallet?.id,
                    },
                });
                break;
            case ActionSlugs.BULK_TRANSFER:
                navigate("/transfert/bulk-transfert", {
                    state: {
                        preselectedWalletId: currentWallet?.id.toString(),
                    },
                });
                break;
            case ActionSlugs.PAY_BILLS:
                navigate("/billing/pay-bill", {
                    state: {
                        preselectedWalletId: currentWallet?.id.toString(),
                    },
                });
                break;
            case ActionSlugs.MANAGE_ACCOUNT:
                if (currentWallet?.id) {
                    navigate(`/admin/wallets/${currentWallet?.id}/details`);
                } else {
                    navigate("/admin/wallets");
                }
                break;
            case ActionSlugs.EXPORT:
                handleOpenCreateDrawer();
                break;
            case ActionSlugs.EXPORT_TEMPLATES:
                handleOpenCreateTemplateDrawer();
                break;
            default:
                break;
        }
    };

    return (
        <div className='quick-actions-container'>
            <div className='quick-actions-content'>
                <div className='header'>
                    <Typography className='fw-bold' message={t("Dashboard.quick-actions")} size='lg' />
                </div>
                <div className='content'>
                    {!isLoadingUser && !walletsLoading
                        ? actions.map((action: Action) => (
                              <div key={action.slug} className='action' onClick={handleRedirect(action.slug)}>
                                  <Icon name={action.icon} size='md' color='lilas' />
                                  <Typography message={t(action.label)} className='fw-bold' size='xs' />
                              </div>
                          ))
                        : Array.from({ length: 5 }).map((_, index) => {
                              const minDelay = 0.1;
                              const maxDelay = 0.5;
                              const randomDelay = Math.random() * (maxDelay - minDelay) + minDelay;
                              const animationDelay = `${randomDelay * 1000}ms`;
                              return <div key={index} className='action-loading' style={{ animationDelay }} />;
                          })}
                </div>
            </div>
            <CreateExportDrawer
                handleOpenCreateDrawer={handleOpenCreateDrawer}
                isOpen={createDrawerOpen}
                preSelectedWalletIds={currentWallet ? currentWallet?.id : computeWalletsIds}
            />
            <CreateTemplateBodyDrawer
                handleOpenCreateDrawer={handleOpenCreateTemplateDrawer}
                isOpen={createTemplateDrawerOpen}
                isDuplicate={false}
                preSelectedWalletIds={currentWallet ? currentWallet?.id : computeWalletsIds}
            />
        </div>
    );
};
