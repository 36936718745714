import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getTransactionsStateItems = (state: RootState) => state.walletsTransactions.items;

export const getWalletTransactions = createSelector(
    getTransactionsStateItems,
    (_state: RootState, { walletId }: { walletId: string }) => walletId,
    (transactionsState, walletId) => transactionsState[walletId]?.data ?? []
);

export const getWalletTransaction = createSelector(
    getWalletTransactions,
    (_state: RootState, { uuid }: { uuid: string | null }) => uuid,
    (walletTransactionsState, transactionUuid) =>
        transactionUuid && walletTransactionsState.find((transaction) => transaction.uuid === transactionUuid)
);

export const getWalletTransactionsCurrentPage = createSelector(
    getTransactionsStateItems,
    (_state: RootState, { walletId }: { walletId: string }) => walletId,
    (transactionsState, walletId) => transactionsState[walletId]?.currentPage ?? 1
);

export const getWalletTransactionsLoading = createSelector(
    getTransactionsStateItems,
    (_state: RootState, { walletId }: { walletId: string }) => walletId,
    (transactionsState, walletId) => transactionsState[walletId]?.isLoading ?? false
);

export const getWalletTransactionsCount = createSelector(
    getTransactionsStateItems,
    (_state: RootState, { walletId }: { walletId: string }) => walletId,
    (transactionsState, walletId) => transactionsState[walletId]?.count
);

export const getWalletTransactionsShowPageSize = createSelector(
    getTransactionsStateItems,
    (_state: RootState, { walletId }: { walletId: string }) => walletId,
    (transactionsState, walletId) => transactionsState[walletId]?.pageSize
);

export const getWalletTransactionsTotalPages = createSelector(
    getTransactionsStateItems,
    (_state: RootState, { walletId }: { walletId: string }) => walletId,
    (transactionsState, walletId) => transactionsState[walletId]?.totalPages
);

const getWalletsTransactionFilters = (state: RootState) => state.walletsTransactions.filters;

export const getWalletTransactionsIsActiveFilters = createSelector(
    getWalletsTransactionFilters,
    (filters) => Object.keys(filters).length > 0
);

export const getWalletTransactionFilters = createSelector(
    getWalletsTransactionFilters,
    (walletFilters) => walletFilters ?? {}
);

export const getWalletTransactionsQueryFilter = createSelector(
    getWalletsTransactionFilters,
    (filtersState) => filtersState?.query
);

export const getWalletTransactionsStartDateFilter = createSelector(
    getWalletsTransactionFilters,
    (filtersState) => filtersState?.startDate
);

export const getWalletTransactionsEndDateFilter = createSelector(
    getWalletsTransactionFilters,
    (filtersState) => filtersState?.endDate
);

export const getWalletTransactionsStatusFilter = createSelector(
    getWalletsTransactionFilters,
    (filtersState) => filtersState?.status
);

export const getWalletTransactionsTasksFilter = createSelector(
    getWalletsTransactionFilters,
    (filtersState) => filtersState?.tasks
);

export const getWalletTransactionsTypeFilter = createSelector(
    getWalletsTransactionFilters,
    (filtersState) => filtersState?.transactionType
);

export const getWalletTransactionsArchivedFilter = createSelector(
    getWalletsTransactionFilters,
    (filtersState) => filtersState?.archived
);

export const getWalletTransactionsFilterIsTouched = createSelector(getWalletTransactionFilters, (state) => {
    return Object.values(state).some((value) => value !== undefined);
});

const getWalletsTransactionsStateItems = (state: RootState) => state.walletsTransactions.allWalletsItems;

export const getAllWalletsTransactions = createSelector(getWalletsTransactionsStateItems, (state) => state.data) ?? [];

export const getAllWalletsTransactionsLoading = createSelector(
    getWalletsTransactionsStateItems,
    (state) => state.isLoading
);
export const getAllWalletsTransactionsCount = createSelector(getWalletsTransactionsStateItems, (state) => state.count);
export const getAllWalletsTransactionsTotalPages = createSelector(
    getWalletsTransactionsStateItems,
    (state) => state.totalPages
);
export const getAllWalletsTransactionsCurrentPage = createSelector(
    getWalletsTransactionsStateItems,
    (state) => state.currentPage
);
export const getAllWalletsTransactionsPageSize = createSelector(
    getWalletsTransactionsStateItems,
    (state) => state.pageSize
);
