import { ColumnItems, exportFormatOptions } from "../../const";
import { ColumnSelection } from "../shared/ColumnSelection";
import { DrawerHeaderForm } from "../shared/DrawerHeader";
import { ExportFormat } from "../shared/ExportFormat";
import { NameFieldExport } from "../shared/NameFIeldExport";
import moment from "moment";
import {
    billingtransactionStatusesFilterOptions,
    getTransactionTypeSlugs,
    transactionTypesFilterOptions,
} from "src/shared/const/transactions";
import {
    ExportFormData,
    ExportFormType,
    ExportFormatData,
    ExportTemplate,
    ExportTemplateFormType,
} from "src/shared/models/Export";

import { useEffect, useMemo, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { useTasksOptionsForAuthorizedWallets } from "src/modules/tasks/hooks";
import { getWalletsAsSelectOptions } from "src/modules/wallets/selectors";
import { fetchWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Radio } from "src/shared/atoms/Radio/Radio";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { CustomDatePicker } from "src/shared/components/DatePicker/DatePicker";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

const formatDate = (date: moment.Moment) => moment(date).format("DD/MM/YYYY");
const createDateRange = (start: moment.Moment, end: moment.Moment) => `${formatDate(start)} - ${formatDate(end)}`;
const getDateRanges = (t: TFunction) => ({
    today: {
        label: t("DateRange.today"),
        value: "today",
        date: formatDate(moment()),
    },
    thisMonth: {
        label: t("DateRange.this-month"),
        value: "thisMonth",
        date: createDateRange(moment().startOf("month"), moment()),
    },
    lastSevenDays: {
        label: t("DateRange.last-seven-days"),
        value: "lastSevenDays",
        date: createDateRange(moment().subtract(6, "days"), moment()),
    },
    lastMonth: {
        label: t("DateRange.last-month"),
        value: "lastMonth",
        date: createDateRange(
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month")
        ),
    },
});

export const ExportCompareSelectedWithTotal = (selectedItems: string[] | undefined, totalItems: SelectOption[]) => {
    if (selectedItems === undefined) {
        return undefined;
    }

    const totalItemIds = new Set(totalItems.map((item: any) => item.id));

    if (selectedItems.length === 0) {
        return undefined;
    } else if (selectedItems.length === totalItems.length && selectedItems.every((id: any) => totalItemIds.has(id))) {
        return ["all"];
    } else {
        return selectedItems;
    }
};

export const exportCheckNameValidation = (name: string) => {
    const nameFilled = name.trim().length > 0;
    const nameValid = /^[a-z0-9 _-]+$/i.test(name);
    const isNameValid = nameFilled && nameValid;

    return isNameValid;
};

type ExportPersonalisedCreationProps = {
    formType?: ExportFormType | ExportTemplateFormType;
    template?: ExportTemplate;
    handleFormDataChange: (data: ExportFormData) => void;
    preSelectedFilters?: ExportFormData;
    sendFormValidation: (validation: boolean) => void;
    handleSaveAsTemplate: (save: boolean) => void;
    preSelectedWalletIds?: number[] | number;
};

export const ExportPersonalisedCreation = ({
    formType = "personalised",
    handleFormDataChange,
    template,
    preSelectedFilters,
    sendFormValidation,
    handleSaveAsTemplate,
    preSelectedWalletIds,
}: ExportPersonalisedCreationProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const transactionTypesOptions = useMemo(() => billingtransactionStatusesFilterOptions(t), [t]);

    const isLimitedPersonalized = useSubscriptionRestrictions(
        SubscriptionRestrictions.LIMIT_EXPORT_PERSONALIZED_30_DAYS
    );
    const isLimitedRegular = useSubscriptionRestrictions(SubscriptionRestrictions.LIMIT_EXPORT_REGULAR_1_YEAR);
    const isLimitedAccount = formType === "personalised" ? isLimitedPersonalized : isLimitedRegular;
    const startDateLimit =
        formType === "personalised"
            ? moment().subtract(1, "days").endOf("day").subtract(30, "days")
            : moment().subtract(1, "days").endOf("day").subtract(1, "years");

    const [exportFormatFormValidation, setExportFormatFormValidation] = useState(false);
    const [validatedForm, setValidatedForm] = useState(false);
    const [name, setName] = useState(template ? template.name : "");
    const [selectedDateRange, setSelectedDateRange] = useState(isLimitedAccount ? "today" : "all");
    const [customStartDate, setCustomStartDate] = useState<Date | undefined>();
    const [customEndDate, setCustomEndDate] = useState<Date | undefined>();
    const [selectedWallets, setSelectedWallets] = useState<SelectOption[] | null>(null);
    const [transactionStatus, setTransactionStatus] = useState<SelectOption[] | null>(
        transactionTypesOptions.map((option) => option)
    );
    const [transactionTypes, setTransactionTypes] = useState<SelectOption[] | null>(
        transactionTypesFilterOptions.map((option) => option)
    );
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [listEmails, setListEmails] = useState<Array<string>>();
    const [exportFormats, setExportFormats] = useState<Array<string>>();
    const [selectedTasks, setSelectedTasks] = useState<SelectOption[] | null>();
    const [errorNameMessage, setErrorNameMessage] = useState<string | undefined>();

    const dateRanges = useMemo(() => getDateRanges(t), [t]);
    const walletOptions = useAppSelector((state) => getWalletsAsSelectOptions(state));

    const selectedWalletsId = useMemo(
        () => (selectedWallets ? selectedWallets.map((wallet) => wallet.id) : []),
        [selectedWallets]
    );
    const columnItems = useMemo(() => ColumnItems(t), [t]);

    const [selectedMinDate, setSelectedMinDate] = useState<string | undefined>();
    const [selectedMaxDate, setSelectedMaxDate] = useState<string | undefined>();

    const { tasksOptions, loading } = useTasksOptionsForAuthorizedWallets(
        selectedWalletsId,
        selectedMinDate,
        selectedMaxDate
    );

    const computeDates = () => {
        let minDate, maxDate;

        if (selectedDateRange === "personalised") {
            minDate = customStartDate ? moment(customStartDate).format("YYYY-MM-DD") : undefined;
            maxDate = customEndDate ? moment(customEndDate).format("YYYY-MM-DD") : undefined;
        } else if (selectedDateRange && selectedDateRange in dateRanges) {
            const dateRangeKey = selectedDateRange as keyof typeof dateRanges;
            const [startDateStr, endDateStr] = dateRanges[dateRangeKey].date.split(" - ");
            const startDate = moment(startDateStr, "DD/MM/YYYY");
            const endDate = moment(endDateStr, "DD/MM/YYYY");
            minDate = startDate.isValid() ? startDate.format("YYYY-MM-DD") : undefined;
            maxDate = endDate.isValid() ? endDate.format("YYYY-MM-DD") : undefined;
        }

        return { minDate, maxDate };
    };
    useEffect(() => {
        const { minDate, maxDate } = computeDates();

        setSelectedMinDate(minDate);
        setSelectedMaxDate(maxDate);
    }, [selectedDateRange, customEndDate, customStartDate]);

    useEffect(() => {
        if (!walletOptions.length) {
            dispatch(fetchWalletsAuthUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (preSelectedWalletIds) {
            const selectedWalletsFromTemplate = walletOptions.filter((wallet) =>
                Array.isArray(preSelectedWalletIds)
                    ? preSelectedWalletIds.includes(Number(wallet.id))
                    : preSelectedWalletIds === Number(wallet.id)
            );
            setSelectedWallets(selectedWalletsFromTemplate);
        }
    }, [preSelectedWalletIds]);

    const getFormData = () => {
        const selectedWalletIds = selectedWallets?.map((wallet) => wallet.id) ?? [];
        const selectedStatuses = formType === "personalised" ? transactionStatus?.map((s) => s.id) ?? [] : [];
        const selectedTransactionTypes =
            formType === "personalised" ? transactionTypes?.map((type) => type.id) ?? [] : [];
        let selectedTasksIds = selectedTasks?.map((task) => task.id) ?? [];

        const finalTransactionTypes = ExportCompareSelectedWithTotal(
            selectedTransactionTypes,
            transactionTypesFilterOptions
        );
        const finalStatuses = ExportCompareSelectedWithTotal(
            selectedStatuses,
            billingtransactionStatusesFilterOptions(t)
        );
        const finalExportFormats = ExportCompareSelectedWithTotal(exportFormats, exportFormatOptions);
        const finalColumns = ExportCompareSelectedWithTotal(selectedColumns, columnItems);

        if (selectedTasksIds.length === tasksOptions.length) {
            selectedTasksIds = tasksOptions.length > 0 ? ["all"] : [];
        }

        const { minDate, maxDate } = computeDates();

        return {
            name,
            minDate,
            maxDate,
            walletIds: selectedWalletIds.length > 0 ? selectedWalletIds : undefined,
            recipients: listEmails,
            isBankStatement: formType === "wallet",
            transactionTypes:
                finalTransactionTypes && !finalTransactionTypes.includes("all")
                    ? getTransactionTypeSlugs(finalTransactionTypes)
                    : finalTransactionTypes,
            transactionStatus: finalStatuses,
            formats: finalExportFormats,
            columns: finalColumns,
            taskIds: selectedTasksIds.length > 0 ? selectedTasksIds : undefined,
        };
    };

    useEffect(() => {
        if (preSelectedFilters) {
            if (preSelectedFilters.walletIds) {
                const walletIdStrings = preSelectedFilters.walletIds.map((id) => id.toString());
                const selectedWalletsFromTemplate = walletOptions.filter((wallet) =>
                    walletIdStrings.includes(wallet.id)
                );
                setSelectedWallets(selectedWalletsFromTemplate);
            }
            if (preSelectedFilters.transactionTypes) {
                const selectedTransactionTypes = transactionTypesFilterOptions.filter((transactionType) =>
                    preSelectedFilters?.transactionTypes?.includes(transactionType.id)
                );
                setTransactionTypes(selectedTransactionTypes);
            }
            if (preSelectedFilters.transactionStatus) {
                const selectedTransactionStatus = transactionTypesOptions.filter((transactionStatus) =>
                    preSelectedFilters?.transactionStatus?.includes(transactionStatus.id)
                );
                setTransactionStatus(selectedTransactionStatus);
            }
            if (preSelectedFilters.minDate) {
                setSelectedDateRange("personalised");
                setCustomStartDate(new Date(preSelectedFilters.minDate));
            }

            if (preSelectedFilters.maxDate) {
                setSelectedDateRange("personalised");
                setCustomEndDate(new Date(preSelectedFilters.maxDate));
            }

            if (preSelectedFilters.name) {
                setName(preSelectedFilters.name);
            }

            if (!preSelectedFilters.minDate && !preSelectedFilters.maxDate) {
                setSelectedDateRange("all");
            }
        }
    }, [preSelectedFilters, walletOptions]);

    useEffect(() => {
        if (template) {
            if (template.transactionTypes) {
                let selectedTransactionTypes: SelectOption[] | null;
                if (template.transactionTypes.includes("all")) {
                    selectedTransactionTypes = transactionTypesFilterOptions;
                } else {
                    selectedTransactionTypes = template.transactionTypes.flatMap((id) => {
                        const option = transactionTypesFilterOptions.find((option) => option.id === id.toString());
                        return option ? [option] : [];
                    });
                    selectedTransactionTypes = selectedTransactionTypes.length > 0 ? selectedTransactionTypes : null;
                }
                setTransactionTypes(selectedTransactionTypes);
            }

            if (template.walletIds) {
                const walletIdStrings = template.walletIds.map((id) => id.toString());
                const selectedWalletsFromTemplate = walletOptions.filter((wallet) =>
                    walletIdStrings.includes(wallet.id)
                );
                setSelectedWallets(selectedWalletsFromTemplate);
            }
            if (template.columns) {
                setSelectedColumns(template.columns);
            }
            if (template.transactionStatus) {
                let selectedTransactionStatus: SelectOption[] | null;
                if (template.transactionStatus.includes("all")) {
                    selectedTransactionStatus = transactionTypesOptions;
                } else {
                    selectedTransactionStatus = transactionTypesOptions.filter((transactionStatus) =>
                        template?.transactionStatus?.includes(transactionStatus.id)
                    );
                }
                setTransactionStatus(selectedTransactionStatus);
            }
            if (template.name) {
                setName(template.name);
            }
        }
    }, [template, walletOptions]);

    useEffect(() => {
        handleFormDataChange(getFormData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        name,
        selectedDateRange,
        selectedColumns,
        listEmails,
        selectedWallets,
        exportFormats,
        exportFormatFormValidation,
        customStartDate,
        customEndDate,
        transactionStatus,
        transactionTypes,
        selectedTasks,
    ]);

    useEffect(() => {
        const isNameValid = exportCheckNameValidation(name);
        setErrorNameMessage(name.length > 0 && !isNameValid ? t<string>("Export.name-must-be-alphanum") : undefined);

        const isSelectedWallets = !!(selectedWallets && selectedWallets.length > 0);
        const isSelectedDateRange = selectedDateRange !== "";
        const isSelectedTransactionType = !!(transactionTypes && transactionTypes.length > 0);
        const isSelectedTransactionStatus = !!(transactionStatus && transactionStatus.length > 0);
        const isAtLeastSelectedOneColumn = !!(selectedColumns && selectedColumns.length > 0);
        const isCustomDateInvalid =
            selectedDateRange === "personalised" && (customStartDate === undefined || customEndDate === undefined);

        const isUserRestricted = () => {
            if (!isLimitedAccount) {
                return true;
            } else {
                const isOneYearLimit =
                    selectedDateRange === "personalised" && customStartDate
                        ? moment(customStartDate).isAfter(startDateLimit)
                        : true;

                return isOneYearLimit;
            }
        };

        const isPersonalizedFormValid =
            isNameValid &&
            isSelectedWallets &&
            isSelectedDateRange &&
            isSelectedTransactionStatus &&
            isSelectedTransactionType &&
            isAtLeastSelectedOneColumn &&
            !isCustomDateInvalid &&
            isUserRestricted();

        const isBankStatementFormValid =
            isNameValid && isSelectedWallets && isSelectedDateRange && isUserRestricted() && !isCustomDateInvalid;

        setValidatedForm(formType === "personalised" ? isPersonalizedFormValid : isBankStatementFormValid);
    }, [
        name,
        selectedWallets,
        selectedDateRange,
        transactionStatus,
        transactionTypes,
        selectedColumns,
        customStartDate,
        customEndDate,
    ]);

    useEffect(() => {
        sendFormValidation(validatedForm && exportFormatFormValidation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatedForm, exportFormatFormValidation]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDateRangeChange = (event: { target: { value: any } }) => {
        const { value } = event.target;
        setSelectedDateRange(value);

        if (value !== "personalised") {
            setCustomStartDate(undefined);
            setCustomEndDate(undefined);
        }
    };

    const handleSetStartDateFilter = (date: Date | null) => {
        setCustomStartDate(date ?? undefined);
    };

    const handleSetEndDateFilter = (date: Date | null) => {
        setCustomEndDate(date ?? undefined);
    };

    const handleSetSelectedWallets = (newSelectedWallets: SelectOption[]) => {
        setSelectedWallets(newSelectedWallets.length === 0 ? null : newSelectedWallets);
    };

    const handleChangeName = (name: string) => {
        setName(name);
    };

    const handleSetStatus = (newStatus: SelectOption[]) => {
        setTransactionStatus(newStatus.length === 0 ? null : newStatus);
    };

    const handleSetTransactionTypes = (newStatus: SelectOption[]) => {
        setTransactionTypes(newStatus.length === 0 ? null : newStatus);
    };

    const onColumnDataChange = (columnData: string[]) => {
        setSelectedColumns(columnData);
    };

    const onExportFormatDataChange = (exportFormatData: ExportFormatData) => {
        if (exportFormatData.formats) {
            setExportFormats(exportFormatData.formats);
        }
        if (exportFormatData.recipients) {
            setListEmails(exportFormatData.recipients);
        }
    };

    const handleExportFormatValidation = (validation: boolean) => {
        setExportFormatFormValidation(validation);
    };

    const radioOptions = useMemo(
        () =>
            Object.values(dateRanges).map((range) => {
                if (formType === "personalised" && range.value === "lastMonth" && isLimitedAccount) {
                    return (
                        <ToolTip
                            content={
                                <div className='tooltip-limited'>
                                    <Typography message={t("Subscription.30-days-limited")} className='fw-bold' />
                                    <Typography message={t("Subscription.unlock-unlimited-access")} />
                                </div>
                            }
                            position='top'
                            activation={true}
                        >
                            <Radio
                                label={
                                    <div className='date-range-label'>
                                        <Typography message={range.label} />
                                        <Typography message={range.date} className='date-range-date' />
                                    </div>
                                }
                                checked={selectedDateRange === range.value}
                                onChange={handleDateRangeChange}
                                value={"all"}
                                disabled={true}
                            />
                        </ToolTip>
                    );
                }

                return (
                    <Radio
                        key={range.value}
                        label={
                            <div className='date-range-label'>
                                <Typography message={range.label} />
                                <Typography message={range.date} className='date-range-date' />
                            </div>
                        }
                        checked={selectedDateRange === range.value}
                        onChange={handleDateRangeChange}
                        value={range.value}
                    />
                );
            }),
        [dateRanges, selectedDateRange, handleDateRangeChange]
    );

    useEffect(() => {
        if (!loading) {
            const newTasksSelected = selectedTasks?.filter((task) =>
                tasksOptions.some((taskOption) => taskOption.id === task.id)
            );

            if (newTasksSelected?.length !== selectedTasks?.length) {
                setSelectedTasks(newTasksSelected);
            }
        }
    }, [loading, selectedTasks, selectedWallets, tasksOptions]);

    const handleSetTasks = (newTasks: SelectOption[]) => {
        setSelectedTasks(newTasks.length === 0 ? null : newTasks);
    };

    return (
        <div className='create-export-template-personalised'>
            <div className='drawer-content-section'>
                <DrawerHeaderForm isFormValid={validatedForm} formTitle={t("Export.informations")} />
                <div className='drawer-content-section-body'>
                    <div className='create-export-template-form-container'>
                        <NameFieldExport
                            name={name}
                            handleChangeName={handleChangeName}
                            errorNameMessage={errorNameMessage}
                        />
                        <div className='label-container'>
                            <Typography message={t("Export.export-period")} className='fw-bold' />
                            <Typography message={" *"} className='fw-bold color-error' />
                        </div>
                        {isLimitedAccount && (
                            <Alert
                                message={
                                    formType === "personalised"
                                        ? t("Subscription.30-days-limited")
                                        : t("Subscription.one-year-limited")
                                }
                                subMessage={t<string>("Subscription.unlock-unlimited-access")}
                                icon='lock'
                                color='warning'
                            />
                        )}
                        {radioOptions}
                        <ToolTip
                            content={
                                <div className='tooltip-limited'>
                                    <Typography message={t("Subscription.30-days-limited")} className='fw-bold' />
                                    <Typography message={t("Subscription.unlock-unlimited-access")} />
                                </div>
                            }
                            position='top'
                            activation={isLimitedAccount}
                        >
                            <Radio
                                label={<Typography className='fw-bold' message={t<string>("CommonUse.all")} />}
                                checked={selectedDateRange === "all"}
                                onChange={handleDateRangeChange}
                                value={"all"}
                                disabled={isLimitedAccount}
                            />
                        </ToolTip>
                        <Radio
                            label={<Typography className='fw-bold' message={t<string>("CommonUse.Personalised")} />}
                            checked={selectedDateRange === "personalised"}
                            onChange={handleDateRangeChange}
                            value={"personalised"}
                        />
                        {selectedDateRange === "personalised" && (
                            <div className='date-picker-container'>
                                <CustomDatePicker
                                    label={t("CommonUse.select-date-range")}
                                    startDate={customStartDate}
                                    onChangeStartDate={handleSetStartDateFilter}
                                    endDate={customEndDate}
                                    onChangeEndDate={handleSetEndDateFilter}
                                    className='background-color-neutral-50'
                                />

                                {isLimitedAccount && moment(customStartDate).isBefore(startDateLimit) && (
                                    <div className='error-message-one-year'>
                                        <Icon name='x-circle-filled' size='sm' />
                                        <Typography
                                            message={
                                                formType === "personalised"
                                                    ? t("Subscription.30-days-limited")
                                                    : t("Subscription.one-year-limited")
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        <SelectMultipleOptions
                            label={t<string>("CommonUse.accounts")}
                            selected={selectedWallets}
                            items={walletOptions}
                            name={t("Export.select-export-accounts")}
                            onSelectionChange={handleSetSelectedWallets}
                            allSelectable
                            mandatory
                        />
                        {formType === "personalised" && (
                            <>
                                <SelectMultipleOptions
                                    label={t<string>("CommonUse.Tasks")}
                                    disabled={!tasksOptions.length}
                                    selected={selectedTasks}
                                    items={tasksOptions}
                                    name={t(!tasksOptions.length ? "Export.wallet-no-tasks" : "Export.export-tasks")}
                                    onSelectionChange={handleSetTasks}
                                    allSelectable
                                />
                                <SelectMultipleOptions
                                    label={t<string>("Transactions.transactions-status")}
                                    selected={transactionStatus}
                                    items={transactionTypesOptions}
                                    name={t("Export.select-export-transactions-status")}
                                    onSelectionChange={handleSetStatus}
                                    allSelectable
                                    mandatory
                                />
                                <SelectMultipleOptions
                                    label={t<string>("Transactions.transactions-types")}
                                    selected={transactionTypes}
                                    items={transactionTypesFilterOptions}
                                    name={t("Export.select-export-transactions-types")}
                                    onSelectionChange={handleSetTransactionTypes}
                                    allSelectable
                                    mandatory
                                />
                                <ColumnSelection handleColumnDataChange={onColumnDataChange} template={template} />
                            </>
                        )}
                    </div>
                </div>
            </div>

            <ExportFormat
                forceFormat={formType !== "personalised"}
                handleFormDataChanged={onExportFormatDataChange}
                template={template}
                sendIsFormValidated={handleExportFormatValidation}
                savable={formType === "personalised"}
                handleSetSaveTemplate={handleSaveAsTemplate}
            />
        </div>
    );
};
