import { TransactionSlug } from "../../services/transactions/types";

import { TFunction } from "i18next";

import { IconListType } from "../atoms/Icons/IconList";

const transactionStatuses = (t: TFunction<"translation", undefined, "translation">) => ({
    waiting: t("Transactions.waiting"),
    failed: t("Transactions.failed"),
    done: t("Tasks.success"),
    cancelled: t("CommonUse.canceled"),
});

const billingTransactionStatuses = (t: TFunction<"translation", undefined, "translation">) => ({
    waiting: t("Transactions.waiting"),
    failed: t("Transactions.failed"),
    done: t("Tasks.success"),
});

export const transactionStatusArchived = "archived";

interface TransactionTypes {
    [key: number]: string;
}

interface TransactionTypesSlug {
    [key: number]: string;
}

interface TransactionMap {
    type: "image" | "icon";
    title: string;
    img: IconListType;
    phoneFormat: boolean;
    creditCard?: boolean;
}

// TODO: translation

export const transactionTypes: TransactionTypes = {
    3: "Transfert Julaya",
    5: "Transfert Mobile Money",
    21: "Approvisionnement bancaire",
    28: "Transfert Wave",
    18: "Transfert carte bancaire",
    17: "Transfert bancaire",
    29: "Transfert international",
    31: "Décaissement crédit",
    32: "Remboursement crédit",
    19: "Paiement de factures",
    33: "Encaissement de factures",
    36: "Parrainage",
    30: "Paiement marchand",
    25: "Dépôt Cash",
    16: "Accès Pro",
};

export const transactionTypesSlug: TransactionTypesSlug = {
    3: "transfer",
    5: "disposal",
    21: "bank-disposal",
    28: "wave-transfer",
    18: "cb-transfer",
    17: "bank-transfer",
    29: "international-transfer",
    31: "loan-disbursement",
    32: "loan-repayment",
    19: "bill-payment",
    33: "bill-repayment",
    36: "reward",
    30: "merchant-payment",
    25: "momo-deposit",
    16: "subscription",
};

export function getTransactionTypeSlugs(typeIds: string[]) {
    return typeIds.map((typeId) => transactionTypesSlug[typeId as any]);
}

export function computeSimpleObjectToSelectOptions(object: { [key: string]: string } | { [key: number]: string }) {
    let selectOptions = Object.entries(object).map(([key, value]) => ({
        id: key,
        label: value,
    }));

    selectOptions = selectOptions.sort((a, b) => a.label.localeCompare(b.label));
    return selectOptions;
}

//TODO: translations
export const moneyOutCancelReasons = {
    PROCESSING_TOO_LONG: "Délais de traitement trop long",
    WRONG_DESTINATION: "Mauvais destinataire",
    WRONG_AMOUNT: "Mauvais montant",
    WRONG_SERVICE: "Mauvais service",
    WRONG_WALLET: "Mauvais compte utilisé",
    OTHER: "Autre",
};

//TODO: translations
const bankDisposalCancelReasons = {
    PROCESSING_TOO_LONG: "Délais de traitement trop long",
    WRONG_AMOUNT: "Mauvais montant",
    WRONG_SERVICE: "Mauvais service",
    WRONG_WALLET: "Mauvais compte utilisé",
    OTHER: "Autre",
};

export const transactionTypesFilterOptions = computeSimpleObjectToSelectOptions(transactionTypes);
export const transactionStatusesFilterOptions = (t: TFunction<"translation", undefined, "translation">) =>
    computeSimpleObjectToSelectOptions(transactionStatuses(t));
export const billingtransactionStatusesFilterOptions = (t: TFunction<"translation", undefined, "translation">) =>
    computeSimpleObjectToSelectOptions(billingTransactionStatuses(t));
export const bankDisposalCancelReasonsOptions = computeSimpleObjectToSelectOptions(bankDisposalCancelReasons);
export const moneyOutCancelReasonsOptions = computeSimpleObjectToSelectOptions(moneyOutCancelReasons);

//TODO: translations in title
export const operationTypes: {
    [key in TransactionSlug]: {
        icon: IconListType;
        title: string;
        color?: "primary" | "success" | "error" | "neutral" | "warning" | "blue" | "lilas";
    };
} = {
    subscription: { icon: "arrows-right-left-filled", title: "Abonnement" },
    airtime: { icon: "phone-plus-circle", title: "Crédit Téléphonique", color: "blue" },
    referal: { icon: "arrows-right-left-filled", title: "Apporteur d'affaire" },
    reward: { icon: "arrows-right-left-filled", title: "Parrainage" },
    "bank-disposal": { icon: "wallet", title: "Approvisionnement", color: "lilas" },
    buy: { icon: "wallet", title: "Rechargement MoMo/CB" },
    "bank-transfer": { icon: "bank", title: "Virement Bancaire", color: "success" },
    "cb-transfer": { icon: "credit-card", title: "Transfert vers CB", color: "warning" },
    transfer: { icon: "julaya-circle", title: "Julaya", color: "warning" },
    purchase: { icon: "placeholder", title: "Achat de carte" },
    "wizall-transfer": { icon: "arrows-right-left-filled", title: "Transfert Wizall", color: "warning" },
    disposal: { icon: "arrows-right-left-filled", title: "Mobile Money", color: "warning" },
    "momo-deposit": { icon: "wallet", title: "Dépôt cash" },
    "merchant-payment": { icon: "wallet", title: "Paiement Wave" },
    "wave-transfer": { icon: "arrows-right-left-filled", title: "Transfert Wave", color: "blue" },
    "international-transfer": {
        icon: "arrows-right-left-filled",
        title: "Transfert international",
        color: "warning",
    },
    "loan-disbursement": { icon: "bills", title: "Décaissement crédit", color: "neutral" },
    "loan-repayment": { icon: "bills", title: "Remboursement crédit", color: "neutral" },
    "bill-payment": { icon: "bills", title: "Paiement de factures", color: "neutral" },
    "bill-repayment": { icon: "bills", title: "Encaissement de factures", color: "neutral" },
    "bill-payment-ext": { icon: "bills", title: "Paiement de factures", color: "neutral" },
    "bill-repayment-ext": { icon: "bills", title: "Encaissement de factures", color: "neutral" },
    load: { icon: "credit-card", title: "Crédit" },
    deduction: { icon: "credit-card", title: "Débit" },
    authorisation: { icon: "credit-card", title: "Autorisation" },
    task: { icon: "placeholder", title: "Tâche" },
    "deduct-card-ben": { icon: "credit-card", title: "Retour de fonds Carte JULAYA" },
    "deduct-card-sen": { icon: "credit-card", title: "Retour de fonds Carte JULAYA" },
    "deduct-card-civ": { icon: "credit-card", title: "Retour de fonds Carte JULAYA" },
} as const;

export const transactionTypesMap: { [key: string]: TransactionMap } = {
    transfer: {
        type: "image",
        title: "JULAYA",
        img: require("src/shared/images/operators/julaya.png"),
        phoneFormat: true,
    },
    "wizall-transfer": {
        type: "image",
        title: "Wizall",
        img: require("src/shared/images/operators/wizall.png"),
        phoneFormat: true,
    },
    "wave-transfer": {
        type: "image",
        title: "Wave",
        img: require("src/shared/images/operators/wave.png"),
        phoneFormat: true,
    },
    disposal: {
        type: "icon",
        title: "Mobile Money",
        img: "arrows-right-left",
        phoneFormat: true,
    },
    "international-transfer": {
        type: "icon",
        title: "Mobile Money International",
        img: "globe",
        phoneFormat: true,
    },
    "bank-transfer": {
        type: "icon",
        title: "Compte bancaire",
        img: "bank",
        phoneFormat: false,
    },
    airtime: {
        type: "icon",
        title: "Crédit téléphonique",
        img: "device-phone-mobile",
        phoneFormat: true,
    },
    "cb-transfer": {
        type: "icon",
        title: "Carte bancaire",
        img: "credit-card",
        phoneFormat: true,
    },
    "bill-payment": {
        type: "icon",
        title: "Paiement de factures",
        img: "bills",
        phoneFormat: false,
    },
};

export const selectTransactionTypesMap: { [key: string]: TransactionMap } = {
    "wave-transfer": {
        type: "image",
        title: "Wave",
        img: require("src/shared/images/operators/wave.png"),
        phoneFormat: true,
    },
    disposal: {
        type: "icon",
        title: "Mobile Money",
        img: "arrows-right-left",
        phoneFormat: true,
    },
    "bank-transfer": {
        type: "icon",
        title: "Compte bancaire",
        img: "bank",
        phoneFormat: false,
    },
    "cb-transfer": {
        type: "icon",
        title: "Carte bancaire",
        img: "credit-card",
        phoneFormat: false,
    },
};

type TransactionServiceSlugImage = {
    [key: string]: any;
};

export const transactionServiceSlugImage: TransactionServiceSlugImage = {
    "disposal-mtn-civ": require("src/shared/images/operators/mtn-money.png"),
    "disposal-mtn-ben": require("src/shared/images/operators/mtn-money.png"),
    "disposal-moov-civ": require("src/shared/images/operators/moov-money.png"),
    "disposal-moov-ben": require("src/shared/images/operators/moov-money.png"),
    "momo-deposit-moov-civ": require("src/shared/images/operators/moov-money.png"),
    "momo-deposit-collect-wave-civ": require("src/shared/images/operators/wave.png"),
    "momo-deposit-collect-wave-sen": require("src/shared/images/operators/wave.png"),
    "momo-deposit-collect-ecobank-civ": require("src/shared/images/operators/xpress.png"),
    "momo-deposit-collect-ecobank-sen": require("src/shared/images/operators/xpress.png"),
    "momo-deposit-collect-ecobank-ben": require("src/shared/images/operators/xpress.png"),
    "merchant-payment-wave-civ": require("src/shared/images/operators/wave.png"),
    "merchant-payment-wave-sen": require("src/shared/images/operators/wave.png"),
    "disposal-orange-civ": require("src/shared/images/operators/orange-money.png"),
    "cb-transfer-djamo-civ": require("src/shared/images/operators/djamo.png"),
    "disposal-orange-sen": require("src/shared/images/operators/orange-money.png"),
    "disposal-emoney-sen": require("src/shared/images/operators/e-money.png"),
    "disposal-free-sen": require("src/shared/images/operators/free-money.png"),
    "disposal-celtiis-ben": require("src/shared/images/operators/celtiis.png"),
    "airtime-mtn-civ": require("src/shared/images/operators/mtn.png"),
    "airtime-moov-civ": require("src/shared/images/operators/moov.png"),
    "airtime-orange-civ": require("src/shared/images/operators/orange.png"),
    "bank-disposal-uba-civ": require("src/shared/images/operators/uba.png"),
    "bank-disposal-ecobank-civ": require("src/shared/images/operators/ecobank.png"),
    "bank-disposal-ecobank-sen": require("src/shared/images/operators/ecobank.png"),
    "bank-disposal-ecobank-ben": require("src/shared/images/operators/ecobank.png"),
    "bank-disposal-sgbs-sen": require("src/shared/images/operators/societe-generale.png"),
    "bank-disposal-boa-sen": require("src/shared/images/operators/boa.png"),
    "bank-disposal-boa-ben": require("src/shared/images/operators/boa.png"),
    "bank-disposal-uba-sen": require("src/shared/images/operators/uba.png"),
    "bank-disposal-cbao-sen": require("src/shared/images/operators/sib.png"),
    "bank-disposal-sgbci-civ": require("src/shared/images/operators/societe-generale.png"),
    "bank-disposal-bdu-civ": require("src/shared/images/operators/bdu.png"),
    "bank-disposal-orabank-civ": require("src/shared/images/operators/orabank.png"),
    "bank-disposal-nsia-civ": require("src/shared/images/operators/nsia.png"),
    "bank-disposal-bicici-civ": require("src/shared/images/operators/bicici.png"),
    "bank-disposal-boa-civ": require("src/shared/images/operators/boa.png"),
    "bank-disposal-gtb-civ": require("src/shared/images/operators/gtb.png"),
    "bank-disposal-sib-civ": require("src/shared/images/operators/sib.png"),
    "bank-disposal-dgtcp-civ": require("src/shared/images/operators/dgtcp.png"),
    "bank-disposal-mansa-civ": require("src/shared/images/operators/mansabank.png"),
    "bank-disposal-mansa-ben": require("src/shared/images/operators/mansabank.png"),
    "bank-disposal-mansa-sen": require("src/shared/images/operators/mansabank.png"),
    "bank-transfer-waemu-civ": require("src/shared/images/operators/bank.png"),
    "bank-transfer-waemu-sen": require("src/shared/images/operators/bank.png"),
    "bank-transfer-waemu-ben": require("src/shared/images/operators/bank.png"),
    "transfer-julaya-civ": require("src/shared/images/operators/julaya.png"),
    "transfer-julaya-ben": require("src/shared/images/operators/julaya.png"),
    "transfer-julaya-internal-civ": require("src/shared/images/operators/julaya.png"),
    "transfer-julaya-internal-sen": require("src/shared/images/operators/julaya.png"),
    "transfer-julaya-internal-ben": require("src/shared/images/operators/julaya.png"),
    "transfer-julaya-external-ben": require("src/shared/images/operators/julaya.png"),
    "wave-transfer-civ": require("src/shared/images/operators/wave.png"),
    "wave-transfer-sen": require("src/shared/images/operators/wave.png"),
    load: require("src/shared/images/operators/cb-transfer.png"),
    deduction: require("src/shared/images/operators/cb-transfer.png"),
    "deduct-card-ben": require("src/shared/images/operators/cb-transfer.png"),
    "deduct-card-civ": require("src/shared/images/operators/cb-transfer.png"),
    "deduct-card-sen": require("src/shared/images/operators/cb-transfer.png"),
    authorisation: require("src/shared/images/operators/cb-transfer.png"),
    "cb-transfer-tutuka-civ": require("src/shared/images/operators/cb-transfer.png"),
    "cb-transfer-tutuka-sen": require("src/shared/images/operators/cb-transfer.png"),
    "cb-transfer-tutuka-ben": require("src/shared/images/operators/cb-transfer.png"),
    "wizall-transfer-cash-civ": require("src/shared/images/operators/wizall.png"),
    "international-transfer-mfs-civ": require("src/shared/images/operators/transfer-international.png"),
    "international-transfer-mfs-sen": require("src/shared/images/operators/transfer-international.png"),
    "bank-transfer-civ": require("src/shared/images/operators/bank.png"),
    "bank-transfer-sen": require("src/shared/images/operators/bank.png"),
    "bank-transfer-ben": require("src/shared/images/operators/bank.png"),
    "bank-transfer-ecobank-civ": require("src/shared/images/operators/bank.png"),
    "loan-disbursement-civ": require("src/shared/images/operators/julaya.png"),
    "loan-disbursement-sen": require("src/shared/images/operators/julaya.png"),
    "bill-payment-ext-abjterm-civ": require("src/shared/images/operators/julaya.png"),
    "bill-repayment-ext-abjterm-civ": require("src/shared/images/operators/julaya.png"),
    "bill-payment-ext-hkb-civ": require("src/shared/images/operators/bip-pass.png"),
    "bill-payment-ext-cie-civ": require("src/shared/images/operators/cie.png"),
    "bill-payment-ext-sodeci-civ": require("src/shared/images/operators/sodeci.png"),
    "bill-payment-ext-woyofal-sen": require("src/shared/images/operators/logo-woyofal.png"),
    "bill-payment-ext-rapido-sen": require("src/shared/images/operators/rapido.png"),
    "bill-payment-ext-xeweul-sen": require("src/shared/images/operators/xeful.png"),
    "reward-referrer-civ": require("src/shared/images/operators/julaya.png"),
    "reward-referrer-sen": require("src/shared/images/operators/julaya.png"),
    "reward-referrer-ben": require("src/shared/images/operators/julaya.png"),
    "reward-referred-civ": require("src/shared/images/operators/julaya.png"),
    "reward-referred-sen": require("src/shared/images/operators/julaya.png"),
    "reward-referred-ben": require("src/shared/images/operators/julaya.png"),
};

//TODO: translations
export const transactionsErrorReasons: { [key: string]: string } = {
    INVALID_PHONE_NUMBER: "Le numéro de téléphone saisi est incorrect. Veuillez le corriger et essayer à nouveau.",
    ERROR_OCCURED: "Une erreur est survenue. Veuillez ressayer ultérieurement.",
    ACCOUNT_NOT_AUTHORIZED: "Le compte choisi n'est pas autorisé à recevoir de l'argent. ",
    SUCCESSFUL: "La transaction a été traité avec succès.",
    ACCOUNT_SUSPENDED: "Le compte Mobile Money du destinataire est suspendu.",
    INVALID_AMOUNT: "Le montant de la transaction est superieur au montant maximum defini pour ce service.",
    ACCOUNT_DAILY_LIMIT_REACHED:
        "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite mensuelle de transaction.",
    ACCOUNT_MONTHLY_LIMIT_REACHED:
        "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite journalière de transaction.",
    ACCOUNT_WEEKLY_LIMIT_REACHED:
        "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite hebdomadaire de transaction.",
    ACCOUNT_LIMIT_REACHED: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde. ",
    ACCOUNT_BLOCKED_OR_DISABLED: "Le compte du destinataire est désactivé ou bloqué.",
    IDENTICAL_DEPOSIT: "Vous venez de réaliser un dépôt identique.",
    ACCOUNT_NOT_FOUND: "Le destinataire ne dispose pas d'un compte Mobile Money.",
    BATCH_CHECKS_NOT_ENOUGHT_MONEY: "Vous n'avez pas assez d'argent sur votre compte.",
    BATCH_CHECKS_MAX_SOLDE: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde.",
    BATCH_CHECKS_MAX_CASHIN: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde.",
};

export const topupErrorReasons: { [key: string]: string } = {
    TOPUP_AMOUNT_DONT_MATCH: "TopupErrorReasons.amount-dont-match",
    TOPUP_RECEIPT_MISSING: "TopupErrorReasons.receipt-missing",
    TOPUP_BANK_DONT_MATCH: "TopupErrorReasons.bank-dont-match",
    TOPUP_TRANSFER_RECEIPT_NOT_SIGNED: "TopupErrorReasons.transfer-receipt-not-signed",
    TOPUP_CHEQUE_NOT_RECEIVED: "TopupErrorReasons.cheque-not-received",
    TOPUP_TRANSACTION_DUPLICATE: "TopupErrorReasons.transaction-duplicate",
    TOPUP_RECEIPT_IS_NOT_READABLE: "TopupErrorReasons.receipt-is-not-readable",
    TOPUP_REJECTION_CUSTOMER_REQUEST: "TopupErrorReasons.rejection-customer-request",
};
