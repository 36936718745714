import { Fragment, ImgHTMLAttributes, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { Icon, IconProps } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Radio } from "src/shared/atoms/Radio/Radio";
import { Select, SelectTypes } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export interface SelectOption {
    id: string;
    label: string;
    prefixImage?: string;
}

export interface SelectOptionWithAvatar extends SelectOption {
    icon?: IconProps;
    image?: ImgHTMLAttributes<HTMLImageElement>;
}

interface SelectMultipleOptionsProps {
    label?: string;
    items: SelectOption[];
    onSelectionChange: (selectedItems: SelectOption[]) => void;
    name: string;
    disabled?: boolean;
    headerClassname?: string;
    selected?: SelectOption[] | null;
    icon?: IconListType;
    handleOnClick?: () => void;
    useRadio?: boolean;
    underMessage?: string;
    showSelectedItems?: boolean;
    variant?: SelectTypes;
    onBlur?: () => void;
    scrollable?: boolean;
    allSelectable?: boolean;
    mandatory?: boolean;
    testId?: string;
    forceClose?: boolean;
    maximumSelection?: { amount: number; entity?: string };
    color?: "grey" | "white" | "darkgrey";
}

export const SelectMultipleOptions = ({
    label,
    name,
    icon,
    items,
    disabled = false,
    selected,
    headerClassname,
    onSelectionChange,
    showSelectedItems = true,
    useRadio = false,
    underMessage,
    variant,
    onBlur,
    scrollable = false,
    allSelectable,
    mandatory = false,
    forceClose,
    testId,
    maximumSelection,
    color,
}: SelectMultipleOptionsProps) => {
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState<SelectOption[]>(selected ?? []);
    const [maxSelectionReached, setMaxSelectionReached] = useState(false);

    useEffect(() => {
        if (!selected) {
            setSelectedItems([]);
        } else {
            setSelectedItems(selected);
        }
    }, [selected]);

    const handleItemClick = (item: SelectOption) => {
        if (item.id === "selectAll") {
            handleSelectAllToggle();
            return;
        }

        if (useRadio) {
            setSelectedItems([item]);
            onSelectionChange([item]);
        } else {
            const itemIndex = selectedItems.findIndex((i) => i.id === item.id);

            if (itemIndex === -1) {
                if (maximumSelection && selectedItems.length >= maximumSelection.amount) {
                    setMaxSelectionReached(true);
                    return;
                }
                setMaxSelectionReached(false);
                const newSelectedItems = [...selectedItems, item];
                setSelectedItems(newSelectedItems);
                onSelectionChange(newSelectedItems);
            } else {
                const updatedItems = [...selectedItems];
                updatedItems.splice(itemIndex, 1);
                setSelectedItems(updatedItems);
                onSelectionChange(updatedItems);
                setMaxSelectionReached(false);
            }
        }
    };

    const InputComponent = useRadio ? Radio : Checkbox;
    const title = showSelectedItems && selectedItems.length ? selectedItems.map((item) => item.label).join(", ") : name;

    const handleSelectAllToggle = () => {
        if (selectedItems.length === items.length) {
            setSelectedItems([]);
            onSelectionChange([]);
            setMaxSelectionReached(false);
        } else {
            if (maximumSelection && maximumSelection.amount < items.length) {
                const itemsToSelect = items.slice(0, maximumSelection.amount);
                setSelectedItems(itemsToSelect);
                onSelectionChange(itemsToSelect);
                setMaxSelectionReached(true);
            } else {
                setSelectedItems(items);
                onSelectionChange(items);
                setMaxSelectionReached(false);
            }
        }
    };

    return (
        <div className='select-multiple-option-container'>
            {label && (
                <div className='label-container'>
                    <Typography message={label} className='fw-bold' />
                    {mandatory && <Typography message={" *"} className='fw-bold color-error' />}
                </div>
            )}
            <Select
                color={color}
                overflowAuto
                className={scrollable ? "high-render" : ""}
                type={variant}
                onBlur={onBlur}
                forceClose={forceClose}
                testId={testId}
                content={{
                    header: { title, icon, disabled, className: headerClassname },
                    dropDownContent: {
                        body: (
                            <div className='item-container'>
                                {maximumSelection && (
                                    <Typography
                                        message={t("CommonUse.max-entity-dropdown", {
                                            count: maximumSelection.amount,
                                            entity: maximumSelection.entity ?? t("CommonUse.selected"),
                                        })}
                                    />
                                )}
                                {allSelectable && (
                                    <Fragment key='selectAll'>
                                        <InputComponent
                                            name={name}
                                            value='selectAll'
                                            checked={selectedItems.length === items.length}
                                            onChange={handleSelectAllToggle}
                                            label={
                                                <Typography
                                                    className='my-auto'
                                                    message={`${t("CommonUse.select-all")} (${items.length})`}
                                                />
                                            }
                                            disabled={
                                                disabled || (maximumSelection && maximumSelection.amount < items.length)
                                            }
                                        />
                                    </Fragment>
                                )}
                                {items.map((item, index) => (
                                    <Fragment key={index}>
                                        <InputComponent
                                            name={name}
                                            value={item.id}
                                            checked={selectedItems.some((i) => i.id === item.id)}
                                            onChange={() => handleItemClick(item)}
                                            label={<Typography className='my-auto' message={item.label} />}
                                            testId={`select-item-${index}`}
                                            disabled={
                                                disabled ||
                                                (maximumSelection &&
                                                    selectedItems.length >= maximumSelection.amount &&
                                                    !selectedItems.some((i) => i.id === item.id))
                                            }
                                        />
                                    </Fragment>
                                ))}
                                {maxSelectionReached && (
                                    <Typography
                                        message={t("You have reached the maximum selection limit.")}
                                        className='error-message mt-2'
                                    />
                                )}
                            </div>
                        ),
                    },
                }}
            />
            {underMessage && variant !== "primary" && (
                <div className={`under-message variant-${variant} fadeInUp`}>
                    {variant === "success" && <Icon name='check-circle-filled' />}
                    {variant === "error" && <Icon name='x-circle-filled' />}
                    {variant === "warning" && <Icon name='exclamation-triangle-filled' />}
                    <Typography message={underMessage} variant='p' className='ml-1 mt-0' />
                </div>
            )}
        </div>
    );
};
