import { ExportFrequencies } from "../../const";
import { exportCheckNameValidation } from "../Export/Personalised";
import { DrawerHeaderForm } from "../shared/DrawerHeader";
import { SelectFrequency } from "./SelectFrequency";
import { BankStatementExportTemplate, ExportFormatData } from "src/shared/models/Export";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getWalletsAsSelectOptions } from "src/modules/wallets/selectors";
import { fetchWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { ExportFormat } from "src/components/Export/Drawers/shared/ExportFormat";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

type ScheduledWalletTemplateProps = {
    handleFormDataChange: (data: any) => void;
    template: BankStatementExportTemplate | undefined;
    sendFormValidation: (validation: boolean) => void;
    preSelectedWalletIds?: number[] | number;
};
export const ScheduledWalletTemplate = ({
    handleFormDataChange,
    template,
    sendFormValidation,
    preSelectedWalletIds,
}: ScheduledWalletTemplateProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const exportFrequecies = useMemo(() => ExportFrequencies(t), [t]);
    const walletOptions = useAppSelector((state) => getWalletsAsSelectOptions(state));

    const [validatedForm, setValidatedForm] = useState(false);
    const [exportFormatFormValidation, setExportFormatFormValidation] = useState(false);

    const [name, setName] = useState(template ? template.name : "");
    const [selectedFrequency, setSelectedFrequency] = useState<SelectOption>();
    const [listEmails, setListEmails] = useState<Array<string>>();
    const [selectedWallets, setSelectedWallets] = useState<SelectOption[] | null>();
    const [errorNameMessage, setErrorNameMessage] = useState<string | undefined>();

    useEffect(() => {
        if (preSelectedWalletIds) {
            const selectedWalletsFromTemplate = walletOptions.filter((wallet) =>
                Array.isArray(preSelectedWalletIds)
                    ? preSelectedWalletIds.includes(Number(wallet.id))
                    : preSelectedWalletIds === Number(wallet.id)
            );
            setSelectedWallets(selectedWalletsFromTemplate);
        }
    }, [preSelectedWalletIds]);

    useEffect(() => {
        if (!walletOptions.length) {
            dispatch(fetchWalletsAuthUser());
        }
    }, []);

    const getFormData = () => {
        const selectedWalletIds = selectedWallets?.map((wallet) => wallet.id) || [];

        return {
            name,
            frequency: selectedFrequency?.id,
            recipients: listEmails,
            walletIds: selectedWalletIds.length > 0 ? selectedWalletIds : undefined,
        };
    };

    useEffect(() => {
        if (template) {
            if (template.frequency) {
                const selectedFrequencyFromTemplate = exportFrequecies.filter((frequency) =>
                    template?.frequency?.includes(frequency.id)
                );
                setSelectedFrequency(selectedFrequencyFromTemplate[0]);
            }
            if (template.walletIds) {
                const walletIdStrings = template.walletIds.map((id) => id.toString());
                const selectedWalletsFromTemplate = walletOptions.filter((wallet) =>
                    walletIdStrings.includes(wallet.id)
                );
                setSelectedWallets(selectedWalletsFromTemplate);
            }
        }
    }, [template, walletOptions, exportFrequecies]);

    useEffect(() => {
        handleFormDataChange(getFormData());
    }, [name, selectedFrequency, listEmails]);

    useEffect(() => {
        const isNameValid = exportCheckNameValidation(name);
        setErrorNameMessage(name.length > 0 && !isNameValid ? t<string>("Export.name-must-be-alphanum") : undefined);

        const isFrequencyValid = selectedFrequency !== undefined;
        const isWalletSelected = !!(selectedWallets && selectedWallets.length > 0);

        const isFormValid = isNameValid && isFrequencyValid && isWalletSelected;

        setValidatedForm(isFormValid);
    }, [name, selectedFrequency, selectedWallets]);

    useEffect(() => {
        sendFormValidation(validatedForm && exportFormatFormValidation);
    }, [validatedForm, exportFormatFormValidation]);

    const handleSetSelectedWallets = (newSelectedWallets: SelectOption[]) => {
        setSelectedWallets(newSelectedWallets.length === 0 ? null : newSelectedWallets);
    };

    const handleSelectFrequency = (frequency: SelectOption) => {
        setSelectedFrequency(frequency);
    };

    const handleChangeName = (name: string) => {
        setName(name);
    };

    const onExportFormatDataChange = (exportFormatData: ExportFormatData) => {
        if (exportFormatData.recipients) {
            setListEmails(exportFormatData.recipients);
        }
    };

    const handleExportFormatValidation = (validation: boolean) => {
        setExportFormatFormValidation(validation);
    };

    return (
        <div className='create-export-template-personalised'>
            <div className='drawer-content-section'>
                <DrawerHeaderForm isFormValid={validatedForm} formTitle={t("Export.informations")} />
                <div className='drawer-content-section-body'>
                    <div className='create-export-template-form-container'>
                        <div className='required-hint'>
                            <Typography message='*' className='color-error' />
                            <Typography message={t("CommonUse.required-field")} />
                        </div>
                        <Input
                            autoFocus
                            label={t<string>("Export.export-name")}
                            placeholder={t("Export.export-name-placeholder")}
                            value={name}
                            onChange={handleChangeName}
                            underMessage={errorNameMessage}
                            variant={errorNameMessage ? "error" : "primary"}
                            mandatory
                        />
                        <SelectMultipleOptions
                            label={t<string>("CommonUse.accounts")}
                            selected={selectedWallets}
                            items={walletOptions}
                            name={t("Export.select-export-accounts")}
                            onSelectionChange={handleSetSelectedWallets}
                            allSelectable
                            mandatory
                        />
                        <SelectFrequency
                            selectedFrequency={selectedFrequency}
                            setSelectedFrequency={handleSelectFrequency}
                        />
                    </div>
                </div>
            </div>
            <ExportFormat
                handleFormDataChanged={onExportFormatDataChange}
                forceFormat
                template={template}
                sendIsFormValidated={handleExportFormatValidation}
            />
        </div>
    );
};
