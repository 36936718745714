import { WalletHistoryPanel } from "../History/Panel";
import { AnalyticsContainer } from "./Analytics/Container";
import { QuickActions } from "./QuickActions/QuickActions";
import { WalletHistoryWidget } from "./WalletHistoryWidget/WalletHistoryWidget";
import { WalletSelectorListItem } from "./WalletSelector/ListItem";
import { WalletSelector } from "./WalletSelector/WalletSelector";
import { useLocation } from "react-router-dom";
import { CompanySubscriptionPlanName } from "src/shared/models/AuthCompany";
import { CompanyRole } from "src/shared/models/UserCompany";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useEffect, useMemo, useState } from "react";

import { t } from "i18next";

import { GetJulayaWalletsTotalBalance, getJulayaWallets } from "src/modules/wallets/selectors";
import { fetchWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";
import { useSubscriptionAuthorized } from "src/shared/hooks/useSubscriptionAuthorized";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { ToggleSwitch, ToggleSwitchOption } from "src/shared/atoms/Toggler/Toggler";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

import "./styles.scss";

export const firstOptionDisplay: ToggleSwitchOption = { icon: "trending-up", label: "NavLinks.accueil" };
export const secondOptionDisplay: ToggleSwitchOption = { icon: "list-bullet", label: "Billing.history" };

export const DashboardPaidPanel = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { walletId: routeWalletId, taskId } = location.state || {};

    const wallets = useAppSelector((state) => getJulayaWallets(state));
    const totalWalletsBalance = useAppSelector((state) => GetJulayaWalletsTotalBalance(state));
    const isAdmin = useIsUserAuthorized({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.READ] });
    const isSubscriptionAuthorized = useSubscriptionAuthorized({
        allowedPlans: [
            CompanySubscriptionPlanName.PRO,
            CompanySubscriptionPlanName.PREMIUM,
            CompanySubscriptionPlanName.LEGACY,
        ],
    });

    const [display, setDisplay] = useState<ToggleSwitchOption>(
        isSubscriptionAuthorized ? firstOptionDisplay : secondOptionDisplay
    );
    const [selectedWallet, setSelectedWallet] = useState<number | null>(0);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [isWalletSelectorDrawerOpen, setIsWalletSelectorDrawerOpen] = useState(false);

    useEffect(() => {
        if (routeWalletId) {
            const walletIndex = wallets.findIndex((wallet) => wallet.id === routeWalletId);
            if (walletIndex !== -1) {
                setSelectedWallet(walletIndex);
            }
        }
    }, [routeWalletId]);

    useEffect(() => {
        if (taskId !== undefined || routeWalletId !== undefined) {
            handleChangeDisplay(secondOptionDisplay);
        }
    }, [taskId, routeWalletId]);

    useEffect(() => {
        dispatch(fetchWalletsAuthUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeDisplay = (newDisplay: ToggleSwitchOption) => {
        if (isSubscriptionAuthorized) {
            setDisplay(newDisplay);
        }
    };

    const handleWalletSelectorDrawerClose = () => {
        setIsWalletSelectorDrawerOpen(false);
    };

    const handleOpenWalletSeletorDrawer = () => {
        setIsWalletSelectorDrawerOpen(true);
    };

    const handleSelectWallet = (walletId: number) => () => {
        setSelectedWallet(walletId);
        setIsWalletSelectorDrawerOpen(false);
        handleChangeDisplay(firstOptionDisplay);
    };

    const handleClickSwitch = () => {
        handleChangeDisplay(secondOptionDisplay);
    };

    const handleSetisLoadingHistory = (value: boolean) => {
        setIsLoadingHistory(value);
    };

    const handleSelectAllWallets = () => {
        setSelectedWallet(null);
        setIsWalletSelectorDrawerOpen(false);
        handleChangeDisplay(firstOptionDisplay);
    };

    const computedWallets = useMemo(() => {
        return (
            <div
                className='wallet-selector-list-item-container first-item'
                data-testid={`wallet-selector-drawer-list-item-all`}
                onClick={handleSelectAllWallets}
            >
                <div className='list-item-container'>
                    <div className='title-container'>
                        <div className='title'>
                            <Icon name='squares' size='md' />
                            <Typography className='fw-bold' message={t("WalletSelector.all-accounts")} />
                        </div>
                        <Typography message={wallets.length + " " + t("CommonUse.wallets")} />
                    </div>
                </div>
                <div className='amount-container'>
                    <Typography message={formatMoneyToString({ amount: totalWalletsBalance })} variant='h5' />
                </div>
            </div>
        );
    }, [wallets, totalWalletsBalance]);

    return (
        <>
            <div className='dashboard-paid-container'>
                {wallets && (
                    <WalletSelector
                        selectedWallet={selectedWallet}
                        handleOpenWalletSeletorDrawer={handleOpenWalletSeletorDrawer}
                    />
                )}

                {isSubscriptionAuthorized && (
                    <ToggleSwitch
                        selected={display}
                        onChange={handleChangeDisplay}
                        firstOption={firstOptionDisplay}
                        secondOption={secondOptionDisplay}
                        disabled={isLoadingHistory}
                    />
                )}
                {display === firstOptionDisplay ? (
                    <>
                        <QuickActions currentWallet={selectedWallet !== null ? wallets[selectedWallet] : null} />
                        <WalletHistoryWidget
                            selectedWallet={selectedWallet !== null ? wallets[selectedWallet]?.id.toString() : null}
                            handleClickSwitch={handleClickSwitch}
                            handleSetIsLoadingHistory={handleSetisLoadingHistory}
                            displayWalletColumn={selectedWallet === null}
                        />
                        <AnalyticsContainer walletId={selectedWallet !== null ? wallets[selectedWallet]?.id : null} />
                    </>
                ) : (
                    <WalletHistoryPanel
                        propWalletId={selectedWallet !== null ? wallets[selectedWallet]?.id.toString() : null}
                        displayHeader={false}
                        handleSetIsLoadingHistory={handleSetisLoadingHistory}
                        displayWalletColumn={selectedWallet === null}
                    />
                )}
            </div>

            <Drawer
                isOpen={isWalletSelectorDrawerOpen}
                onClose={handleWalletSelectorDrawerClose}
                header={{
                    title: t("Wallets.my-wallets"),
                    image: "wallet",
                }}
                body={
                    <List
                        className='wallet-selector-card-list'
                        animation='fadeInRight'
                        keyName='bankAccount'
                        extended={true}
                        selectedValue={
                            selectedWallet !== null ? selectedWallet + (isAdmin && isSubscriptionAuthorized ? 1 : 0) : 0
                        }
                        items={
                            isAdmin && isSubscriptionAuthorized
                                ? [
                                      computedWallets,
                                      ...Object.values(wallets).map((wallet, index) => (
                                          <WalletSelectorListItem
                                              handleSelect={handleSelectWallet(index)}
                                              wallet={wallet}
                                              key={`Wallet-list-${wallet.id}`}
                                          />
                                      )),
                                  ]
                                : Object.values(wallets).map((wallet, index) => (
                                      <WalletSelectorListItem
                                          handleSelect={handleSelectWallet(index)}
                                          wallet={wallet}
                                          key={`Wallet-list-${wallet.id}`}
                                      />
                                  ))
                        }
                    />
                }
            />
        </>
    );
};
