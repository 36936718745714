import { useEffect, useState } from "react";

import {
    getAllWalletsTransactions,
    getAllWalletsTransactionsCount,
    getAllWalletsTransactionsLoading,
    getWalletTransactions,
    getWalletTransactionsCount,
    getWalletTransactionsIsActiveFilters,
    getWalletTransactionsLoading,
} from "src/modules/walletsTransactions/selectors";
import { setWalletTransactionCurrentPage, setWalletTransactionPageSize } from "src/modules/walletsTransactions/slice";
import {
    fetchWalletTransactions,
    handleRequestCompaniesTransactions,
} from "src/modules/walletsTransactions/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { TransactionHistoryTable } from "src/components/History/Table";
import { HistoryWalletDrawerContainer } from "src/components/History/Wallet/Drawer/Container";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type WalletHistoryWidgetProps = {
    selectedWallet: string | null;
    handleClickSwitch: () => void;
    handleSetIsLoadingHistory: (isLoading: boolean) => void;
    displayWalletColumn?: boolean;
};

export const WalletHistoryWidget = ({
    selectedWallet,
    handleClickSwitch,
    handleSetIsLoadingHistory,
    displayWalletColumn = false,
}: WalletHistoryWidgetProps) => {
    const dispatch = useAppDispatch();

    const walletId = selectedWallet ?? "";
    const isLoading = useAppSelector((state) => getWalletTransactionsLoading(state, { walletId }));
    const totalCount = useAppSelector((state) => getWalletTransactionsCount(state, { walletId }));
    const transactions = useAppSelector((state) => getWalletTransactions(state, { walletId }));
    const activeFilters = useAppSelector((state) => getWalletTransactionsIsActiveFilters(state));
    const allWalletsTransactions = useAppSelector((state) => getAllWalletsTransactions(state)) ?? [];
    const allWalletsTransactionsCount = useAppSelector((state) => getAllWalletsTransactionsCount(state));
    const allWalletsTransactionsLoading = useAppSelector((state) => getAllWalletsTransactionsLoading(state));

    const [selectedTransactionUuid, setSelectedTransactionUuid] = useState<string | null>(null);

    const handleOpenDrawer = (transactionId: string | null) => () => {
        setSelectedTransactionUuid(transactionId);
    };

    const handleCloseDrawer = () => {
        setSelectedTransactionUuid(null);
    };

    const handleSetPageSize = (newPageSize: number) => {
        dispatch(setWalletTransactionPageSize({ walletId, pageSize: newPageSize }));
    };

    const handleSetCurrentPage = (newPage: number) => {
        dispatch(setWalletTransactionCurrentPage({ walletId, currentPage: newPage }));
    };

    useEffect(() => {
        handleSetIsLoadingHistory(isLoading || allWalletsTransactionsLoading);
    }, [isLoading, allWalletsTransactionsLoading]);

    useEffect(() => {
        if (!isLoading) {
            if (selectedWallet !== null) {
                handleFetchWalletTransactions();
            } else {
                handleFetchAllWAlletTransactions();
            }
        }
    }, [walletId]);

    const handleFetchWalletTransactions = () => {
        dispatch(
            fetchWalletTransactions({
                walletId,
                filters: {},
                page: 1,
                pageSize: 5,
            })
        );
    };

    const handleFetchAllWAlletTransactions = () => {
        dispatch(handleRequestCompaniesTransactions({ filters: {}, page: 1, pageSize: 5 }));
    };

    return (
        <>
            <div className='wallet-history-widget'>
                <div className='wallet-history-widget-content'>
                    <div className='header'>
                        <Typography message='Transactions récentes' className='fw-bold' size='lg' />
                        <Button
                            icon='chevron-right'
                            size='md'
                            label={"Voir tout"}
                            position='right'
                            onClick={handleClickSwitch}
                        />
                    </div>
                    <TransactionHistoryTable
                        transactions={selectedWallet !== null ? transactions : allWalletsTransactions}
                        handleOpenDrawer={handleOpenDrawer}
                        currentPage={0}
                        showPageSize={5}
                        totalCount={selectedWallet !== null ? totalCount : allWalletsTransactionsCount}
                        setCurrentPage={handleSetCurrentPage}
                        setPageSize={handleSetPageSize}
                        activeFilters={activeFilters}
                        isLoading={isLoading || allWalletsTransactionsLoading}
                        displayPagination={false}
                        isSelectableTable={false}
                        loadingRows={5}
                        displayWalletColumn={displayWalletColumn}
                    />
                </div>
                <HistoryWalletDrawerContainer
                    walletId={walletId}
                    selectedTransactionUuid={selectedTransactionUuid}
                    selectedTransaction={
                        selectedTransactionUuid
                            ? allWalletsTransactions.find((t) => t.uuid === selectedTransactionUuid)
                            : undefined
                    }
                    handleCloseDrawer={handleCloseDrawer}
                />
            </div>
        </>
    );
};
