import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { requestGetLoans } from "src/services/loans/operations";
import { LoanModelApiResponse } from "src/shared/models/Loan";
import { DrawerContext } from "src/wrapper/context";

import { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

import "./styles.scss";

type TransfertMethod = {
    title: string;
    description: string;
    icon: IconListType;
    link: string;
    id?: string;
};

export function TransfertDrawerBody() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { providedState, setOpen } = useContext(DrawerContext);

    const [activeLoanId, setActiveLoanId] = useState<string | null>(null);

    const handleRedirect = (link: string) => () => {
        navigate(link, { ...(providedState?.sendStateOnRedirection ? { state: providedState } : {}) });
        setOpen(undefined);
    };

    useEffect(() => {
        requestGetLoans().then((response: LoanModelApiResponse) => {
            const loans = response.data.filter((loan) => loan.status.includes("active"));
            if (loans.length) {
                setActiveLoanId(loans[0].id.toString());
            }
        });
    }, []);

    const transferMethods = useMemo(() => {
        const methods: TransfertMethod[] = [
            {
                title: "NavLinks.transfert-rapide",
                description: "TransferDrawer.rapid-transfer-msg",
                icon: "bolt",
                link: "/transfert/rapid-transfert",
                id: "drawer_topup_iban",
            },
            {
                title: "CommonUse.add-task",
                description: "TransferDrawer.bulk-transfer-msg",
                icon: "bulk",
                link: "/transfert/bulk-transfert",
                id: "drawer_topup_bank-transfer",
            },
            {
                title: "Billing.pay-bill",
                description: "TransferDrawer.pay-bill-msg",
                icon: "receipt-accepted",
                link: "/billing/pay-bill",
                id: "drawer_topup_pay-bill",
            },
        ];

        if (activeLoanId) {
            methods.push({
                title: "TransferDrawer.loan-repayment-title",
                description: "TransferDrawer.loan-repayment-msg",
                icon: "get-paid",
                link: `/transfert/loan/${activeLoanId}`,
                id: "drawer_topup_loan-repayment",
            });
        }

        return methods;
    }, [activeLoanId]);

    return (
        <div className='transfer-drawer-body-container'>
            <List
                extended
                animation='fadeInRight'
                keyName='transfer-drawer-body-item'
                items={transferMethods.map(({ title, description, icon, link, id }) => (
                    <div
                        key={title}
                        onClick={handleRedirect(link)}
                        id={id}
                        className={classNames("transfer-drawer-body-section")}
                    >
                        <Avatar icon={icon} color='lilas-900' backgroundColor='lilas-50' size='lg' />
                        <div className='transfer-drawer'>
                            <Typography size='sm' className='fw-bold' message={t(title)} />
                            <Typography size='sm' message={t(description)} />
                        </div>
                    </div>
                ))}
            />
        </div>
    );
}
