import { logoutResetStore } from "../auth/Actions";
import { CollectorStats, WalletTransactionsStats } from "src/services/stats/types";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface StatsState {
    walletTansactionStatsLoading: boolean;
    walletTransactionsStats: WalletTransactionsStats;
    collectorStatsLoading: boolean;
    collectorStats: CollectorStats;
}

const initialState = {
    walletTansactionStatsLoading: true,
    walletTransactionsStats: {
        stats: [{ date: "", amountOut: 0, amountIn: 0 }],
        totalOut: 0,
        totalIn: 0,
    },
} as StatsState;

export const statsSlice = createSlice({
    name: "stats",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestWalletTransactionsStats: (state) => {
            return {
                ...state,
                walletTansactionStatsLoading: true,
            };
        },
        receiveWalletTransactionsStats: (
            state,
            action: PayloadAction<{ walletTransactionsStats: WalletTransactionsStats }>
        ) => {
            state.walletTansactionStatsLoading = false;
            state.walletTransactionsStats = action.payload.walletTransactionsStats;
        },
        failedRequestWalletTransactionsStats: (state) => {
            state.walletTansactionStatsLoading = false;
        },
        requestCollectorStats: (state) => {
            return {
                ...state,
                collectorStatsLoading: true,
            };
        },
        receiveCollectorStats: (state, action: PayloadAction<{ collectorStats: CollectorStats }>) => {
            state.collectorStatsLoading = false;
            state.collectorStats = action.payload.collectorStats;
        },
        failedRequestCollectorStats: (state) => {
            state.collectorStatsLoading = false;
        },
    },
});

export const {
    requestWalletTransactionsStats,
    receiveWalletTransactionsStats,
    failedRequestWalletTransactionsStats,
    requestCollectorStats,
    receiveCollectorStats,
    failedRequestCollectorStats,
} = statsSlice.actions;

export const statsSliceReducer = statsSlice.reducer;
