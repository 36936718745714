import { InputHTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { IconButton } from "src/shared/components/IconButton/IconButton";

type Props = {
    initialValue: string;
    onValidate?: (value: string) => void;
    editOpen?: boolean;
    testId?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function EditTaskInputCell({
    initialValue,
    onValidate,
    disabled,
    children,
    editOpen,
    testId,
}: PropsWithChildren<Props>) {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(editOpen ?? false);
    const [value, setValue] = useState(initialValue);
    const [valueError, setValueError] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setEdit(editOpen ?? false);
    }, [editOpen]);

    useEffect(() => {
        if (inputRef) {
            inputRef.current?.focus();
        }
    }, [edit, inputRef]);

    useEffect(() => {
        setValue(initialValue !== "null" ? initialValue : "");
    }, [initialValue]);

    useEffect(() => {
        setValueError(!/^[a-zA-Z0-9\s\.\-_]*$/.test(value));
    }, [value]);

    const handleChange = (change: string) => {
        setValue(change);
    };

    const setEditOn = () => {
        setEdit(true);
    };

    const handleCancel = () => {
        setEdit(false);
        setValue(initialValue);
    };

    const handleValidate = () => {
        if (onValidate) {
            setEdit(false);
            onValidate(value);
        }
    };

    return (
        <>
            {edit ? (
                <div className='bulk-amount-input-cell'>
                    <Input
                        autoFocus
                        placeholder=''
                        value={value ?? 0}
                        onChange={handleChange}
                        disabled={disabled}
                        data-testid={testId}
                        className='input-cell-edit-task'
                        variant={valueError ? "error" : undefined}
                        underMessage={valueError ? t<string>("CommonUse.no-spec-char") : undefined}
                    />
                    <Button disabled={disabled} icon='x-mark' onClick={handleCancel} className='button-error' />
                    <Button disabled={disabled} icon='check' onClick={handleValidate} className='button-success' />
                </div>
            ) : (
                <div className='d-flex align-items-center'>
                    <div className='mr-2'>{children}</div>
                    {onValidate && (
                        <IconButton
                            classname='cursor-pointer ml-2'
                            disabled={disabled}
                            testId={testId + "-button-edit"}
                            iconProps={{ name: "pencil" }}
                            handleOnClick={setEditOn}
                        />
                    )}
                </div>
            )}
        </>
    );
}
