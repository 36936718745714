import { WalletBeneficiary } from "src/services/wallets/types";
import { LoanModel } from "src/shared/models/Loan";
import { WalletModel } from "src/shared/models/Wallet";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUserExpensePolicyByWallet } from "src/modules/auth/selectors";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppSelector } from "src/store";

import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { TransferAlert } from "src/components/Transfert/Alert/Alert";
import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { Input } from "src/shared/atoms/Inputs/Input";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { AmountInput } from "src/shared/components/AmountInput/AmountInput";
import { SmsTransferCheckbox } from "src/shared/components/SmsTransferCheckbox/SmsTransferCheckbox";

import "./styles.scss";

type TransfertDetailsProps = {
    debitAccount: WalletModel;
    beneficiary: WalletBeneficiary;
    beneficiaryName: string;
    amount: number;
    comment: string;
    setAmount: (amount: number) => void;
    setComment: (comment: string) => void;
    loanDetails?: LoanModel;
    setSmsMessage: (smsMessage: string | undefined) => void;
    selectedFees: boolean;
    setSelectedFees: (selectedFees: boolean) => void;
    commentError: boolean;
    isLoanRepayment: boolean;
};

export const TransfertDetails = ({
    debitAccount,
    beneficiary,
    beneficiaryName,
    amount,
    comment,
    setAmount,
    setComment,
    loanDetails,
    setSmsMessage,
    selectedFees,
    setSelectedFees,
    commentError,
    isLoanRepayment,
}: TransfertDetailsProps) => {
    const amountToRepayCalculated = loanDetails ? loanDetails.amountToRepay - loanDetails.amountRepaid : 0;

    const { t } = useTranslation();

    const expensePolicy = useAppSelector((state) =>
        getAuthUserExpensePolicyByWallet(state, { walletId: debitAccount.id })
    );

    const walletData = useMemo(
        () => (expensePolicy ? expensePolicy.spentMoneyPerWallet.find((item) => item.walletId === debitAccount.id) : 0),
        [debitAccount.id, expensePolicy]
    );

    const remainingMoneyToSpend =
        expensePolicy && walletData ? expensePolicy.limitPerUnitOfTime - walletData.spentMoney : null;

    const renderAmountInputError = () => {
        if (expensePolicy && expensePolicy.limitPerOperation < amount) {
            return t("Expense-policy.the-amount-exceed-the-allowed-amount-per-operation");
        }
        if (remainingMoneyToSpend && remainingMoneyToSpend < amount) {
            return t("Expense-policy.expense-policy-amount-exceed");
        }
        if (loanDetails && amountToRepayCalculated < amount) {
            return t("TransfertDetails.le-montant-est-superieur-au-montant-restant-a-rembourser");
        }
        if ((debitAccount?.balance ?? 0) < amount) {
            return t("TransfertDetails.le-montant-est-superieur-au-solde-de-votre-compte");
        }
        return undefined;
    };

    const handleSetSmsTemplate = (smsTemplate: string | undefined) => {
        setSmsMessage(smsTemplate);
    };

    const handleSetSelectedFees = () => {
        setSelectedFees(!selectedFees);
    };

    const isSmsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_SMS_TRANSFER_RAPID);

    return (
        <div className='transfert-details-container'>
            {loanDetails && (
                <>
                    <div className='transfert-details-row'>
                        <Typography className='fw-bold' message={t("CommonUse.reference")} />
                        <Typography className='fw-bold' message={loanDetails?.offerId} />
                    </div>
                    <div className='transfert-details-row'>
                        <Typography className='fw-bold' message={t("TransfertDetails.montant-restant-a-rembourser")} />
                        <Typography
                            className='fw-bold'
                            message={formatMoneyToString({ amount: amountToRepayCalculated })}
                        />
                    </div>
                    <div className='divider' />
                </>
            )}

            <div className='transfert-details-row'>
                <Typography className='fw-bold' message={t("TransfertDetails.compte-emetteur")} />
                <Typography className='fw-bold' message={debitAccount.label} />
            </div>

            {!loanDetails && (
                <>
                    <div className='transfert-details-row'>
                        <Typography className='fw-bold' message={t("CommonUse.identifier")} />
                        <Typography className='fw-bold' message={beneficiaryName} />
                    </div>

                    <div className='transfert-details-row'>
                        <Typography className='fw-bold' message={t("CommonUse.reference")} />
                        <Typography className='fw-bold' message={beneficiary.serviceSelected.externalReference} />
                    </div>
                </>
            )}

            <div className='transfert-details-row'>
                <Typography className='fw-bold' message={t("TransfertDetails.solde-disponible")} />
                <Typography className='fw-bold' message={formatMoneyToString({ amount: debitAccount.balance || 0 })} />
            </div>

            <div className='transfert-details-row'>
                <Typography className='fw-bold' message={t("Transactions.service")} />
                <div className='service-row'>
                    <Typography className='fw-bold' message={beneficiary.typeTitle} />
                    <TransactionServiceImage
                        classname='service-image-icon'
                        serviceSlug={beneficiary.serviceSelected.serviceSlug}
                    />
                </div>
            </div>

            <div className='inputs-container'>
                <div className='divider' />
                <TransferAlert walletId={debitAccount?.id} />
                <form autoComplete='off' className='form-amount-container'>
                    <AmountInput
                        autoFocus
                        label={t("TransfertDetails.montant-a-transerer")}
                        name='amount'
                        variant={
                            (debitAccount?.balance ?? 0) < amount || (loanDetails && amountToRepayCalculated < amount)
                                ? "error"
                                : undefined
                        }
                        placeholder='0 FCFA'
                        value={amount}
                        underMessage={renderAmountInputError()}
                        handleChange={setAmount}
                        testId='transfert-details-input-amount'
                    />
                    <Input
                        placeholder={t("TransfertDetails.exemple-rembousement")}
                        data-lpignore='true'
                        value={comment}
                        onChange={setComment}
                        testId='transfert-details-input-comment'
                        variant={commentError ? "error" : undefined}
                        underMessage={commentError ? t<string>("CommonUse.no-spec-char") : undefined}
                    />
                    {!isLoanRepayment && (
                        <>
                            <Checkbox
                                label={
                                    <Typography
                                        className='fw-bold'
                                        message={t("BulkTransferPage.fees-checkbox-label")}
                                    />
                                }
                                checked={selectedFees}
                                onChange={handleSetSelectedFees}
                            />

                            <ToolTip
                                position='bottom'
                                content={
                                    <div className='tooltip-limited'>
                                        <Typography
                                            message={t("Subscription.not-accessible-feature")}
                                            className='fw-bold'
                                        />
                                        <Typography message={t("Subscription.unlock-unlimited-access")} />
                                    </div>
                                }
                                activation={isSmsRestricted}
                            >
                                <SmsTransferCheckbox
                                    handleSetSmsTemplate={handleSetSmsTemplate}
                                    smsVariables={{
                                        comment: comment !== "" ? comment : null,
                                        amount,
                                        receiverName: beneficiaryName,
                                        service: beneficiary.serviceSelected.serviceTitle,
                                    }}
                                    disabled={isSmsRestricted}
                                />
                            </ToolTip>
                        </>
                    )}
                </form>
            </div>
        </div>
    );
};
