import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { adminReducer } from "./modules/admin/slice";
import { authSliceReducer } from "./modules/auth/slice";
import { companiesSliceReducer } from "./modules/authCompanies/slice";
import { billingTransactionsSliceReducer } from "./modules/billing/billingTransactions/slice";
import { billingReducer } from "./modules/billing/reducer";
import { documentReducer } from "./modules/documents/slice";
import { notificationsSliceReducer } from "./modules/notifications/slice";
import { statsSliceReducer } from "./modules/stats/slice";
import { tasksSliceReducer } from "./modules/tasks/slice";
import { tasksItemsSliceReducer } from "./modules/tasksItems/slice";
import { transactionsReducer } from "./modules/transactions/slice";
import { walletsSliceReducer } from "./modules/wallets/slice";
import { walletsBeneficiariesSliceReducer } from "./modules/walletsBeneficiaries/slice";
import { walletTransactionsSliceReducer } from "./modules/walletsTransactions/slice";

const reducer = combineReducers({
    admin: adminReducer,
    billing: billingReducer,
    auth: authSliceReducer,
    authCompanies: companiesSliceReducer,
    wallets: walletsSliceReducer,
    walletsBeneficiaries: walletsBeneficiariesSliceReducer,
    walletsTransactions: walletTransactionsSliceReducer,
    billingTransactions: billingTransactionsSliceReducer,
    tasks: tasksSliceReducer,
    tasksItems: tasksItemsSliceReducer,
    document: documentReducer,
    transactions: transactionsReducer,
    notifications: notificationsSliceReducer,
    stats: statsSliceReducer,
});

export const store = configureStore({
    reducer: reducer,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof reducer>;
