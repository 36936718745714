import { logoutResetStore } from "../auth/Actions";
import { WalletApi, WalletsApi } from "src/services/wallets/types";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface WalletsState {
    requested: boolean;
    sendWalletsLoading: boolean;
    walletsLoading: boolean;
    wallets: WalletsApi;
    authorizedWallets: WalletsApi;
    bankDisposalWallets: WalletsApi;
    sendWallets: WalletsApi;
}

const initialState = {
    requested: false,
    sendWalletsLoading: true,
    walletsLoading: true,
    wallets: {},
    authorizedWallets: {},
    bankDisposalWallets: {},
    sendWallets: {},
} as WalletsState;

export const walletsSlice = createSlice({
    name: "wallets",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestWallets: (state) => {
            return {
                ...state,
                requested: true,
                walletsLoading: true,
            };
        },
        requestSendWallets: (state) => {
            return {
                ...state,
                sendWalletsLoading: true,
            };
        },
        receiveWallets: (state, action: PayloadAction<{ wallets: WalletsState["wallets"] }>) => {
            state.requested = false;
            state.walletsLoading = false;
            state.wallets = action.payload.wallets;
        },
        receiveWallet: (state, { payload }: PayloadAction<{ wallet: WalletApi }>) => {
            state.requested = false;
            state.walletsLoading = false;

            const { wallet } = payload;
            const index = Object.values(state.wallets).findIndex((walletData) => walletData.id === wallet.id);

            state.wallets[index] = wallet;
        },
        receiveAuthorizedWallets: (state, action: PayloadAction<{ wallets: WalletsState["wallets"] }>) => {
            state.requested = false;
            state.walletsLoading = false;

            state.authorizedWallets = action.payload.wallets;
        },
        failedRequestWallets: (state) => {
            return {
                ...state,
                requested: false,
                walletsLoading: false,
            };
        },
        failedRequestSendWallets: (state) => {
            return {
                ...state,
                sendWalletsLoading: false,
            };
        },
        receiveBankDisposalWallets: (state, action: PayloadAction<{ wallets: WalletsState["wallets"] }>) => {
            return {
                ...state,
                requested: false,
                bankDisposalWallets: action.payload.wallets,
            };
        },
        receiveSendWallets: (state, action: PayloadAction<{ wallets: WalletsState["wallets"] }>) => {
            return {
                ...state,
                requested: false,
                sendWallets: action.payload.wallets,
            };
        },
    },
});

export const {
    requestWallets,
    requestSendWallets,
    receiveWallet,
    receiveWallets,
    failedRequestWallets,
    failedRequestSendWallets,
    receiveAuthorizedWallets,
    receiveBankDisposalWallets,
    receiveSendWallets,
} = walletsSlice.actions;

export const walletsSliceReducer = walletsSlice.reducer;
