import { WalletApi } from "src/services/wallets/types";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export interface WalletSelectorComputedData {
    id: number;
    label: string;
    reference: string;
    icon: IconListType;
    balance: number;
}

type WalletSelectorListItemProps = {
    handleSelect: () => void;
    wallet: WalletApi;
};

export function WalletSelectorListItem({ handleSelect, wallet }: WalletSelectorListItemProps) {
    return (
        <div
            onClick={handleSelect}
            className='wallet-selector-list-item-container'
            data-testid={`wallet-selector-drawer-list-item-${wallet.id}`}
        >
            <div className='list-item-container'>
                <div className='title-container'>
                    <Typography className='fw-bold' message={wallet.label} />
                    <Typography message={wallet.reference} />
                </div>
            </div>
            <div className='amount-container'>
                <Typography message={formatMoneyToString({ amount: wallet?.balance })} variant='h5' />
            </div>
        </div>
    );
}
