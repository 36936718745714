import { useLocation } from "react-router-dom";
import { DrawerContext } from "src/wrapper/context";

import { useContext, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getWalletWithIban } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

export function TransferDrawerButton() {
    const location = useLocation();
    const { setOpen, setProvidedState } = useContext(DrawerContext);
    const { t } = useTranslation();

    const wallet = useAppSelector((state) => getWalletWithIban(state));

    const isActive = useMemo(() => {
        return location.pathname === "/transfert/rapid-transfert" || location.pathname === "/transfert/bulk-transfert";
    }, [location.pathname]);

    const handleSetOpen = () => {
        setOpen("transfer");
        if (wallet) {
            setProvidedState({ walletId: String(wallet?.id) });
        }
    };

    return (
        <div
            onClick={handleSetOpen}
            className={`menu-button cursor-pointer ${isActive ? "active" : ""}`}
            data-testid='menu-button-create-task'
            id='sidebar_topup'
        >
            <Icon name='arrows-right-left' color={"black"} />
            <Typography
                className='fw-bold color-black'
                size={"xs"}
                variant={"span"}
                message={t("CommonUse.transfer")}
            />
        </div>
    );
}
