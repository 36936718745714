import {
    receiveCollectorStats,
    receiveWalletTransactionsStats,
    requestCollectorStats,
    requestWalletTransactionsStats,
} from "./slice";
import { fetchCollectorsStatsApi, fetchWalletTransactionsStatsApi } from "src/services/stats/operations";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchWalletTransactionsStats = createAsyncThunk(
    "fetchWalletTransactionsStats",
    async ({ walletId, maxDate, minDate }: { walletId?: number; maxDate?: string; minDate?: string }, { dispatch }) => {
        dispatch(requestWalletTransactionsStats());

        try {
            const walletTransactionsStats = await fetchWalletTransactionsStatsApi({ walletId, maxDate, minDate });
            dispatch(receiveWalletTransactionsStats({ walletTransactionsStats }));
        } catch (error) {
            dispatch(requestWalletTransactionsStats());
        }
    }
);

export const fetchCollectorsStats = createAsyncThunk(
    "fetchCollectorsStats",
    async ({ walletId, maxDate, minDate }: { walletId?: number; maxDate?: string; minDate?: string }, { dispatch }) => {
        dispatch(requestCollectorStats());

        try {
            const collectorStats = await fetchCollectorsStatsApi({ walletId, maxDate, minDate });
            dispatch(receiveCollectorStats({ collectorStats }));
        } catch (error) {
            dispatch(requestCollectorStats());
        }
    }
);
