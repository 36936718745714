import { isTaskItemTmpModel } from "../tasksItems/thunkActions";
import { TaskListApiReturnItem } from "src/services/tasks/types";
import { TransactionApi, TransactionServiceSlug } from "src/services/transactions/types";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import * as XLSX from "xlsx";

const typeSlugs: { [key: string]: string } = {
    disposal: "transfert-mobile-money",
    transfer: "transfert-julaya",
    airtime: "achat-credit-telephonique",
    "bank-transfer": "virement-bancaire",
    "wizall-transfer": "transfert-wizall",
    "wave-transfer": "transfert-wave",
    "cb-transfer": "rechargement-cb",
};

const generateData = (taskItems: TransactionApi[] | TaskItemTmpModel[]) => {
    return taskItems.map((taskItem) => {
        const typeSlug: TransactionServiceSlug = isTaskItemTmpModel(taskItem)
            ? taskItem.serviceSlug
            : taskItem?.Service?.slug;

        const convertedTypeSlug = Object.entries(typeSlugs).find((slug) => typeSlug?.includes(slug?.[0]));

        return [
            taskItem.externalReference,
            taskItem.amount,
            taskItem.externalFullname,
            taskItem.comment,
            convertedTypeSlug?.[1] ?? null,
        ];
    });
};

const header = [
    "Référence (Numéro ou IBAN)",
    "Montant (FCFA)",
    "Nom du Destinataire (obligatoire)",
    "Commentaire (facultatif)",
    "Type d’opération (obligatoire)",
];

const headerColumns: XLSX.ColInfo[] = [{ wch: 24 }, { wch: 20 }, { wch: 30 }, { wch: 24 }, { wch: 22 }];

export const generateTaskExcelFile = (
    taskItems: TransactionApi[] | TaskItemTmpModel[],
    task: TaskListApiReturnItem
) => {
    const data = generateData(taskItems);
    const dataWithHeader = [header, ...data];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeader);
    worksheet["!cols"] = headerColumns;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Informations");

    XLSX.writeFile(workbook, `${task.title}.xlsx`);
};
