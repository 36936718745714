import { middlewareRequest } from "../utils/request";
import { CollectorStats, WalletTransactionsStats } from "./types";

export async function fetchWalletTransactionsStatsApi({
    walletId,
    minDate,
    maxDate,
}: {
    walletId?: number;
    minDate?: string;
    maxDate?: string;
}) {
    try {
        return await middlewareRequest<WalletTransactionsStats>({
            endpoint: "/stats/transactions",
            method: "POST",
            params: { walletId, minDate, maxDate },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function fetchCollectorsStatsApi({
    walletId,
    minDate,
    maxDate,
}: {
    walletId?: number;
    minDate?: string;
    maxDate?: string;
}) {
    try {
        return await middlewareRequest<CollectorStats>({
            endpoint: "/stats/collectors",
            method: "POST",
            params: { walletId, minDate, maxDate },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
