import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { DashboardPaidPanel } from "src/components/DashboardPaid/Panel";

const DashboardPageUnauthorized = () => {
    return <DashboardPaidPanel />;
};

export const DashboardPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.READ, CompanyRole.USER],
})(DashboardPageUnauthorized);
