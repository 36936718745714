import { CompanySubscriptionPlanName } from "../models/AuthCompany";

import { useMemo } from "react";

import { useAuthUserSelectedCompany } from "src/modules/authCompanies/hooks";

type SubscriptionAuthorizedProps = {
    allowedPlans: CompanySubscriptionPlanName[];
};

export function useSubscriptionAuthorized({ allowedPlans }: SubscriptionAuthorizedProps) {
    const company = useAuthUserSelectedCompany();

    return useMemo(() => {
        if (!company || !company.plan) {
            return false;
        }

        const { plan } = company;
        return allowedPlans.includes(plan.name);
    }, [company, allowedPlans]);
}
