import { TransactionReceipt, TransactionsApi, WalletApiFilters } from "./types";
import { fileUploader } from "src/services/utils/fileUploader";
import { middlewareRequest } from "src/services/utils/request";
import { RequestWalletsBeneficiariesApi } from "src/services/wallets/types";
import { ServiceModel } from "src/shared/models/Service";
import { TransactionNotify } from "src/shared/models/Task";
import { getInStorage, getInStorageUserPreferences } from "src/shared/utils/storage";

import { BillReferenceFields } from "src/modules/transactions/thunkActions";

type TransactionBaseRequest = {
    walletId: string;
    transactionUuid: string;
};

export async function requestGetWalletTransactions(walletId: string, filters?: WalletApiFilters) {
    try {
        return await middlewareRequest<TransactionsApi>({
            endpoint: `/wallets/${walletId}/transactions`,
            method: "POST",
            params: filters,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetCompanyWalletsTransactions(filters?: WalletApiFilters) {
    try {
        return await middlewareRequest<TransactionsApi>({
            endpoint: "/me/companies/transactions",
            method: "POST",
            params: filters,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export type UpdateTransactionCommentProps = TransactionBaseRequest & {
    comment: string;
};

export async function updateTransactionComment({ walletId, transactionUuid, comment }: UpdateTransactionCommentProps) {
    try {
        return await middlewareRequest<{ comment: string }>({
            endpoint: `/wallets/${walletId}/transactions/${transactionUuid}/comment`,
            method: "POST",
            params: { comment },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export type UpdateTransactionAttachmentOrReceiptsProps = TransactionBaseRequest & {
    file: File;
    type: string;
    uploader: string | null;
};

export async function updateTransactionAttachmentOrReceipts({
    walletId,
    transactionUuid,
    ...props
}: UpdateTransactionAttachmentOrReceiptsProps) {
    try {
        return await fileUploader<TransactionReceipt>({
            endpoint: `/wallets/${walletId}/transactions/${transactionUuid}/file/attach`,
            params: props,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export type RefundtransactionsProps = {
    uuids: string[];
    reason: string;
    walletId: string;
    password: string;
    twoFactor: string | null;
};

export const refundTransactions = async ({ walletId, twoFactor, ...requestData }: RefundtransactionsProps) => {
    const accessToken = getInStorage("accessToken");

    if (accessToken) {
        try {
            return await middlewareRequest<{ ok: boolean }>({
                endpoint: `/wallets/${walletId}/transactions/refund`,
                method: "POST",
                params: {
                    otp: twoFactor,
                    ...requestData,
                },
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return Promise.reject();
};

export type ArchiveTransactionProps = {
    uuid: string;
    walletId: string;
    password: string;
    twoFactor: string | null;
};

export const requestArchiveTransaction = async ({ password, twoFactor, walletId, uuid }: ArchiveTransactionProps) => {
    const accessToken = getInStorage("accessToken");

    if (accessToken) {
        try {
            return await middlewareRequest<{ ok: boolean }>({
                endpoint: `/wallets/${walletId}/transactions/archive`,
                method: "POST",
                params: {
                    password: password,
                    otp: twoFactor,
                    uuids: [uuid],
                },
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return Promise.reject();
};

export type RetryTransactionProps = {
    uuids: string[];
    walletId: string;
    password: string;
    twoFactor: string | null;
};

export const requestRetryTransaction = async ({ password, twoFactor, walletId, uuids }: RetryTransactionProps) => {
    const accessToken = getInStorage("accessToken");

    if (accessToken) {
        try {
            return await middlewareRequest<{ ok: boolean }>({
                endpoint: `/wallets/${walletId}/transactions/retry`,
                method: "POST",
                params: {
                    password,
                    otp: twoFactor,
                    uuids,
                },
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }
    return Promise.reject();
};

export const getBankServices = async () => {
    try {
        const response = await middlewareRequest<RequestWalletsBeneficiariesApi>({
            endpoint: `/me/companies/services`,
            method: "POST",
            params: { way: "in", typeSlug: "bank-disposal" },
        });

        let services = response[0]?.services ?? [];
        const bankDisposalPreferredBank = getInStorageUserPreferences("bankDisposalPreferredBank");

        let formatedServices: ServiceModel[] = [];

        formatedServices = services.map((service) => {
            let options = service.options ?? {
                accountOwner: "",
                link: "",
                logo: "",
                rib: {
                    accountNumber: "",
                    bankCode: "",
                    counterCode: "",
                    key: "",
                },
            };

            let formatedService = {
                fees: service.fees,
                fixFees: service.fixFees,
                maxAmount: service.maxAmount,
                minAmount: service.minAmount,
                slug: service.serviceSlug,
                title: service.serviceTitle,
                options: options,
                preferredBank: false,
            };

            if (bankDisposalPreferredBank) {
                if (service.serviceSlug === bankDisposalPreferredBank) {
                    formatedService.preferredBank = true;
                }
            }
            return formatedService;
        });
        return formatedServices;
    } catch (error) {
        return Promise.reject(error);
    }
};

type AttachFileToTransactionProps = {
    params: {
        walletId: string;
        transactionId: string;
        file: File;
    };
    type: string;
};

export const attachFileToTransaction = async ({ params, type }: AttachFileToTransactionProps) => {
    try {
        const { walletId, transactionId, file } = params;

        await fileUploader({
            endpoint: `/wallets/${walletId}/transactions/${transactionId}/file/attach`,
            params: { file, type },
        });
    } catch (err) {
        await Promise.reject(err);
    }
};

export type DeleteFileToTransactionProps = {
    walletId: string;
    transactionUuid: string;
    fileId: string;
    fileType: "receipt" | "attachment";
};

export const deleteFileToTransaction = async ({
    walletId,
    fileType,
    transactionUuid,
    fileId,
}: DeleteFileToTransactionProps) => {
    try {
        await middlewareRequest({
            endpoint: `/wallets/${walletId}/transactions/${transactionUuid}/${fileType}/${fileId}`,
            method: "DELETE",
        });
    } catch (err) {
        await Promise.reject(err);
    }
};

export type CreateTransactionParams = {
    walletId: string;
    typeSlug: string;
    way: string;
    title: string;
    code: string;
    taskUuid: string;
    smsTemplate?: string;
    includeReceiverFees?: boolean;
    items: {
        amount: number;
        externalReference?: string;
        externalFullname?: string;
        serviceSlug: string;
        typeSlug: string;
        tags: string | string[];
        comment: string;
    }[];
    otp?: string | null;
};

export type CreateAttachmentParams = {
    file: File;
    walletId: string;
};

export const createTransaction = async (
    params: CreateTransactionParams,
    attachmentParams: CreateAttachmentParams | null = null,
    uniqueId: string
) => {
    const { code, ...restParams } = params;

    try {
        const response = await middlewareRequest<TransactionNotify>({
            endpoint: "/tasks/create/send",
            method: "POST",
            params: {
                ...restParams,
                password: code,
                signatureKey: uniqueId,
            },
        });

        if (response && attachmentParams) {
            const { file, walletId } = attachmentParams;

            await attachFileToTransaction({
                params: {
                    file,
                    transactionId: response.TaskItems[0]?.uuid ?? "",
                    walletId,
                },
                type: "attachment",
            });
        }

        return response;
    } catch (err) {
        return Promise.reject(err);
    }
};

export type CreateBillTransaction = {
    walletId: string;
    way: string;
    title: string;
    code: string;
    taskUuid: string;
    items: ({
        typeSlug: string;
        amount: number;
        externalFullname: string;
        serviceSlug: string;
        comment?: string;
    } & BillReferenceFields)[];
    otp?: string;
    signatureKey?: string;
};

export const createBillTransaction = async (params: CreateBillTransaction) => {
    const { code, ...props } = params;

    try {
        const response = await middlewareRequest<TransactionNotify>({
            endpoint: "/tasks/create/send",
            method: "POST",
            params: {
                ...props,
                password: code,
            },
        });

        return response;
    } catch (err) {
        return Promise.reject(err);
    }
};

export type CancelTransactionRequestPrams = {
    uuids: string[];
    comment: string;
};

export const cancelTransactionRequest = async (walletId: string, params: CancelTransactionRequestPrams) => {
    try {
        return await middlewareRequest({
            endpoint: `/wallets/${walletId}/transactions/cancel`,
            method: "POST",
            params,
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

export async function requestGetBillingTransactions(filters?: WalletApiFilters) {
    try {
        return await middlewareRequest<TransactionsApi>({
            endpoint: "/billing/transactions",
            method: "POST",
            params: filters,
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export async function requestDownloadReceipts({
    transactionsUuids,
    recipients,
}: {
    transactionsUuids: string[] | ["all"];
    recipients: string[];
}) {
    return middlewareRequest({
        endpoint: "/exports/receipts",
        method: "POST",
        params: { transactionsUuids, recipients },
    });
}
