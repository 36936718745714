import { CSSProperties, PropsWithChildren, useEffect, useState } from "react";

import "./styles.scss";

export type AnimationType = "fadeInUp" | "fadeInLeft" | "fadeIn" | "fadeInRight";

export type DelayedProps = PropsWithChildren<{
    delayTime?: number;
    animation?: AnimationType;
    animationDuration?: CSSProperties["animationDuration"];
    className?: string;
}>;

export function Delayed({ delayTime, animation, className, animationDuration = "0.5s", children }: DelayedProps) {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, delayTime);
    }, [delayTime]);

    return visible && children ? (
        <div
            data-testid='test-content-parent-wrapper'
            className={`${animation ? animation : ""} ${className ? className : ""}`}
            style={{ animationDuration }}
        >
            {children}
        </div>
    ) : null;
}
