import { ResponsivePie } from "@nivo/pie";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export type WalletTransactionsPieChartData = {
    id: string;
    label: string;
    value: number;
    color: string;
};

type WalletTransactionsPieChartProps = {
    data: WalletTransactionsPieChartData[] | null;
    colors: string[];
    header: { title: string; icon: IconListType };
};

export const WalletTransactionsPieChart = ({ data, colors, header }: WalletTransactionsPieChartProps) => {
    const { t } = useTranslation();

    return (
        <div className='wallet-transactions-pie-chart-container'>
            <div className='wallet-transactions-pie-chart-header'>
                <Avatar icon={header.icon} size='md' />
                <Typography message={header.title} className='fw-bold' size='sm' />
            </div>
            <div className='wallet-transactions-pie-chart-content'>
                {data === null ? (
                    <Typography message={t("Analytics.no-data")} />
                ) : (
                    <ResponsivePie
                        data={data}
                        arcLabel={(datum) =>
                            `${((datum.value / data.reduce((acc, item) => acc + item.value, 0)) * 100).toFixed(2)}%`
                        }
                        arcLabelsTextColor={"white"}
                        colors={colors}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0}
                        tooltip={({ datum }) => (
                            <div className='wallet-transactions-pie-chart-hover-window'>
                                <Typography message={datum.label + ":"} />
                                <Typography
                                    message={formatMoneyToString({ amount: datum.value })}
                                    className='fw-bold'
                                />
                            </div>
                        )}
                        enableArcLinkLabels={false}
                        padAngle={0.7}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        theme={{
                            text: {
                                fontSize: 14,
                                fontWeight: "bold",
                            },
                        }}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", 0.2]],
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor='#333333'
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLabelsSkipAngle={10}
                        defs={[
                            {
                                id: "dots",
                                type: "patternDots",
                                background: "inherit",
                                color: "rgba(255, 255, 255, 0.3)",
                                size: 4,
                                padding: 1,
                                stagger: true,
                            },
                            {
                                id: "lines",
                                type: "patternLines",
                                background: "inherit",
                                color: "rgba(255, 255, 255, 0.3)",
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                            },
                        ]}
                        legends={[
                            {
                                anchor: "bottom",
                                direction: "row",
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 70,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: "#999",
                                itemDirection: "left-to-right",
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: "circle",
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
};
