import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { DrawerContext } from "src/wrapper/context";

import { SyntheticEvent, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getUserCountryTag } from "src/modules/auth/selectors";
import { GetJulayaWalletsTotalBalance, getJulayaWallets, getWalletsLoading } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Skeleton } from "src/shared/atoms/Skeleton/Skeleton";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type WalletSelectorProps = {
    selectedWallet: number | null;
    handleOpenWalletSeletorDrawer: () => void;
};

export const WalletSelector = ({ selectedWallet, handleOpenWalletSeletorDrawer }: WalletSelectorProps) => {
    const { t } = useTranslation();
    const { setOpen, setProvidedState } = useContext(DrawerContext);

    const wallets = useAppSelector((state) => getJulayaWallets(state));
    const walletsLoading = useAppSelector((state) => getWalletsLoading(state));
    const totalWalletsBalance = useAppSelector((state) => GetJulayaWalletsTotalBalance(state));

    const isAllAccountsSelected = selectedWallet === null;
    const userCountryCode = useAppSelector((state) => getUserCountryTag(state));
    const isCountryDissalowed = ["BEN"].includes(userCountryCode ?? "");

    const [isCopyLinkClicked, setIsCopyLinkClicked] = useState(false);

    const handleOpenIbanDrawer = (event: SyntheticEvent) => {
        event.stopPropagation();
        setOpen("iban");
        if (selectedWallet) {
            setProvidedState({ walletId: String(wallets[selectedWallet]?.id ?? "") });
        }
    };

    useEffect(() => {
        if (isCopyLinkClicked) {
            setTimeout(() => {
                setIsCopyLinkClicked(false);
            }, 2000);
        }
    }, [isCopyLinkClicked]);

    const handleCopyRef = (event: SyntheticEvent) => {
        event.stopPropagation();
        if (selectedWallet !== null) {
            navigator.clipboard.writeText(wallets[selectedWallet]?.reference ?? "");
        }
        setIsCopyLinkClicked(true);
    };

    return (
        <>
            <div className='wallet-selector-container' onClick={handleOpenWalletSeletorDrawer}>
                <div className='left-part'>
                    <Avatar icon='wallet' size='md' />
                    <div className='wallet-info'>
                        <div className='wallet-title'>
                            {walletsLoading ? (
                                <Skeleton />
                            ) : (
                                <Typography
                                    size='xs'
                                    message={
                                        isAllAccountsSelected
                                            ? t("WalletSelector.all-accounts")
                                            : wallets[selectedWallet]?.label
                                    }
                                    className='fw-bold'
                                />
                            )}
                            {!isAllAccountsSelected ? (
                                <>
                                    <div className='dot' />
                                    {walletsLoading ? (
                                        <Skeleton />
                                    ) : (
                                        <div className='reference-container'>
                                            <Typography size='xs' message={wallets[selectedWallet]?.reference} />
                                            {!isCopyLinkClicked ? (
                                                <ToolTip
                                                    activation
                                                    content={
                                                        <Typography message={t("CommonUse.copy")} className='fw-bold' />
                                                    }
                                                    position='bottom'
                                                >
                                                    <Icon name='square-2-stack' size='sm' onClick={handleCopyRef} />
                                                </ToolTip>
                                            ) : (
                                                <ToolTip
                                                    activation
                                                    content={
                                                        <Typography
                                                            message={t("CommonUse.copied")}
                                                            className='fw-bold'
                                                        />
                                                    }
                                                    position='bottom'
                                                >
                                                    <Icon name='check-circle-filled' size='sm' color='success' />
                                                </ToolTip>
                                            )}
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </div>
                        {walletsLoading ? (
                            <Skeleton />
                        ) : (
                            <Typography
                                message={formatMoneyToString(
                                    isAllAccountsSelected
                                        ? { amount: totalWalletsBalance }
                                        : { amount: wallets[selectedWallet]?.balance }
                                )}
                                variant='h5'
                            />
                        )}
                    </div>
                </div>
                <div className='right-part'>
                    {!isAllAccountsSelected
                        ? wallets[selectedWallet]?.iban &&
                          !isCountryDissalowed && (
                              <>
                                  <Button
                                      icon='information-circle'
                                      label={t("WalletSelector.see-rib")}
                                      size='md'
                                      variant='tertiary'
                                      onClick={handleOpenIbanDrawer}
                                  />
                                  <div className='wallet-selector-container-divider' />
                              </>
                          )
                        : null}
                    <Button
                        icon='chevron-up-down'
                        size='lg'
                        variant='tertiary'
                        onClick={handleOpenWalletSeletorDrawer}
                    />
                </div>
            </div>
        </>
    );
};
