import { CollectorBarChart } from "./CollectorBarChart/CollectorBarChart";
import { WalletTransactionsBarChart } from "./WalletTransactionsBarChart/WalletTransactionsBarChart";
import { WalletTransactionsPieChart } from "./WalletTransactionsPieChart/WalletTransactionsPieChart";
import moment from "moment";
import { CompanyRole } from "src/shared/models/UserCompany";

import { useEffect, useMemo, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { getUserCountryTag } from "src/modules/auth/selectors";
import {
    getBarChartFormatedCollectorStats,
    getBarChartFormatedWalletTransactionsStats,
    getPieChartFormatedWalletTransactionsStats,
} from "src/modules/stats/selectors";
import { fetchCollectorsStats, fetchWalletTransactionsStats } from "src/modules/stats/thunkActions";
import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";
import { useAppDispatch, useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Radio } from "src/shared/atoms/Radio/Radio";
import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

const displayStats: SelectOption[] = [
    { id: "transactions", label: "Analytics.global-payments" },
    { id: "collector", label: "Analytics.collectes" },
];

export type AnalyticsDateRanges = {
    label: string;
    value: string;
    minDate: moment.Moment;
    maxDate: moment.Moment;
};

const getDateRanges = (t: TFunction): { [key: string]: AnalyticsDateRanges } => ({
    today: {
        label: t("DateRange.today"),
        value: "today",
        minDate: moment().startOf("day"),
        maxDate: moment(),
    },
    lastWeek: {
        label: t("DateRange.last-seven-days"),
        value: "lastWeek",
        minDate: moment().subtract(1, "week").startOf("week"),
        maxDate: moment().subtract(1, "week").endOf("week"),
    },
    thirtyLastDays: {
        label: t("DateRange.last-thirty-days"),
        value: "thirtyLastDays",
        minDate: moment().subtract(30, "day"),
        maxDate: moment(),
    },
    currentMonth: {
        label: t("DateRange.current-month"),
        value: "currentMonth",
        minDate: moment().startOf("month"),
        maxDate: moment().endOf("month"),
    },
    threeLastMonth: {
        label: t("DateRange.three-last-month"),
        value: "threeLastMonth",
        minDate: moment().subtract(3, "month").startOf("month"),
        maxDate: moment().subtract(1, "month").endOf("month"),
    },
});

type AnalyticsContainerProps = {
    walletId: number | null;
};

export const AnalyticsContainer = ({ walletId }: AnalyticsContainerProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const dateRanges = useMemo(() => getDateRanges(t), [t]);
    const isAdmin = useIsUserAuthorized({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] });
    const userCountryTag = useAppSelector((state) => getUserCountryTag(state));

    const [selectedDisplay, setSelectedDisplay] = useState<SelectOption>(displayStats[0]);

    const [selectedDateRange, setSelectedDateRange] = useState(dateRanges.threeLastMonth);
    const [startDateFilter, setStartDateFilter] = useState<Date>(dateRanges.threeLastMonth.minDate.toDate());
    const [endDateFilter, setEndDateFilter] = useState<Date>(dateRanges.threeLastMonth.maxDate.toDate());

    const [closeSelect, setCloseSelect] = useState(false);

    const formattedPieChartWalletTransactionsStats = useAppSelector(getPieChartFormatedWalletTransactionsStats);
    const formattedBarChartWalletTransactionsStats = useAppSelector(getBarChartFormatedWalletTransactionsStats);
    const formattedBarCollectorStats = useAppSelector(getBarChartFormatedCollectorStats);

    useEffect(() => {
        dispatch(
            fetchWalletTransactionsStats({
                walletId: walletId ? walletId : undefined,
                minDate: startDateFilter ? moment(startDateFilter).format("YYYY-MM-DD") : undefined,
                maxDate: endDateFilter ? moment(endDateFilter).format("YYYY-MM-DD") : undefined,
            })
        );
        if (isAdmin) {
            dispatch(
                fetchCollectorsStats({
                    walletId: walletId ? walletId : undefined,
                    minDate: startDateFilter ? moment(startDateFilter).format("YYYY-MM-DD") : undefined,
                    maxDate: endDateFilter ? moment(endDateFilter).format("YYYY-MM-DD") : undefined,
                })
            );
        }
    }, [startDateFilter, endDateFilter, walletId]);

    const handleDateRangeChange = (selectedRange: AnalyticsDateRanges) => () => {
        const dateRange = Object.values(dateRanges).find((range) => range.value === selectedRange.value);
        if (dateRange) {
            setSelectedDateRange(dateRange);
            setStartDateFilter(moment(dateRange.minDate).toDate());
            setEndDateFilter(moment(dateRange.maxDate).toDate());
        }
        setCloseSelect(true);
    };

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    const handleSelectDisplay = (display: SelectOption) => () => {
        setCloseSelect(true);
        setSelectedDisplay(display);
    };

    const radioOptions = useMemo(
        () =>
            Object.values(dateRanges).map((range) => {
                return (
                    <Radio
                        key={range.value}
                        label={<Typography message={range.label} />}
                        checked={selectedDateRange.value === range.value}
                        onChange={handleDateRangeChange(range)}
                        value={range.value}
                    />
                );
            }),
        [dateRanges, selectedDateRange, handleDateRangeChange]
    );

    return (
        <div className='analytics-container'>
            <div className='header'>
                <Typography message={t("Analytics.Analytics")} className='fw-bold' size='lg' />
                <div className='filter-container'>
                    {isAdmin && userCountryTag !== "BEN" && (
                        <Select
                            color='white'
                            forceClose={closeSelect}
                            content={{
                                header: {
                                    component: (
                                        <div className='header-content'>
                                            <Icon name='trending-up' size='sm' />
                                            <Typography message={t(selectedDisplay.label)} />
                                        </div>
                                    ),
                                },
                                dropDownContent: {
                                    body: (
                                        <div className='body-content'>
                                            {displayStats.map((display) => (
                                                <div
                                                    key={display.id}
                                                    className={`select-item ${
                                                        display.id === selectedDisplay.id ? "selected" : ""
                                                    }`}
                                                    onClick={handleSelectDisplay(display)}
                                                >
                                                    <Typography message={t(display.label)} />
                                                </div>
                                            ))}
                                        </div>
                                    ),
                                },
                            }}
                        />
                    )}
                    <Select
                        color='white'
                        forceClose={closeSelect}
                        content={{
                            header: {
                                title: selectedDateRange.label,
                                icon: "calendar",
                            },
                            dropDownContent: {
                                body: <div className='body-content'>{radioOptions}</div>,
                            },
                        }}
                    />
                </div>
            </div>
            {selectedDisplay.id === "transactions" && (
                <div className='transactions-report-container'>
                    <WalletTransactionsBarChart
                        data={formattedBarChartWalletTransactionsStats}
                        selectedDateRange={selectedDateRange}
                        indexBy='date'
                        keys={[t("Analytics.transactions-in"), t("Analytics.transactions-out")]}
                        colors={["#7EB2FE", "#EF3D34"]}
                        header={{ title: t("Analytics.transactions-report"), icon: "chart-pie" }}
                    />
                    <WalletTransactionsPieChart
                        data={formattedPieChartWalletTransactionsStats}
                        colors={["#7EB2FE", "#EF3D34"]}
                        header={{ title: t("Analytics.transactions-report"), icon: "chart-pie" }}
                    />
                </div>
            )}
            {selectedDisplay.id === "collector" && (
                <CollectorBarChart
                    data={formattedBarCollectorStats}
                    selectedDateRange={selectedDateRange}
                    keys={["Wave", "XPRESS"]}
                    indexBy='collectorName'
                    colors={["hsla(216, 100%, 90%, 1)", "hsla(32, 100%, 87%, 1)"]}
                    header={{ title: t("Analytics.collector-report"), icon: "chart-pie" }}
                />
            )}
        </div>
    );
};
