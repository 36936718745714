import { HistoryWalletDrawerBody } from "./Body";
import { requestDownloadReceipts } from "src/services/transactions/operations";
import { TransactionApi } from "src/services/transactions/types";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { NotificationDrawerContext } from "src/shared/context/notificationDrawer";
import { TransactionContext } from "src/shared/context/transaction";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useContext, useEffect, useState } from "react";

import { getBillingTransaction } from "src/modules/billing/billingTransactions/selectors";
import { useTransactionStatusChange } from "src/modules/walletsTransactions/hooks";
import { getWalletTransaction } from "src/modules/walletsTransactions/selectors";
import { useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

type Props = {
    walletId?: string;
    selectedTransactionUuid: string | null;
    selectedTransaction?: TransactionApi;
    handleCloseDrawer: () => void;
};

export function HistoryWalletDrawerContainer({
    walletId = "",
    selectedTransactionUuid,
    selectedTransaction,
    handleCloseDrawer,
}: Props) {
    const { open } = useContext(NotificationDrawerContext);

    const [hidden, setHidden] = useState(false);
    const [isLoadingReceipt, setIsLoadingReceipt] = useState(false);

    const { actionDialog, setActionDialog, passwordModalError, handleConfirm } = useTransactionStatusChange({
        walletId,
        selectedTransactionUuid,
        onSuccess: handleCloseDrawer,
    });

    const walletTransaction = useAppSelector((state) =>
        walletId
            ? getWalletTransaction(state, { walletId, uuid: selectedTransactionUuid })
            : getBillingTransaction(state, { uuid: selectedTransactionUuid })
    );

    const transaction = selectedTransaction ?? walletTransaction;

    const handleCloseDialog = () => {
        setActionDialog(undefined);
    };

    useEffect(() => {
        if (!open) {
            setHidden(false);
        }
    }, [open]);

    const handleDownloadReceipts = () => {
        if (selectedTransactionUuid && !isLoadingReceipt) {
            setIsLoadingReceipt(true);
            document.body.classList.add("cursor-wait");
            requestDownloadReceipts({ transactionsUuids: [selectedTransactionUuid], recipients: [] })
                .then((downloadLink: any) => {
                    const link = document.createElement("a");
                    link.href = downloadLink.url;
                    link.setAttribute("download", "receipts.pdf");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .finally(() => {
                    document.body.classList.remove("cursor-wait");
                    setIsLoadingReceipt(false);
                });
        }
    };

    const isOpenDrawer = Boolean(selectedTransactionUuid);

    return transaction ? (
        <TransactionContext.Provider value={transaction}>
            <Drawer
                isHidden={hidden}
                isOpen={isOpenDrawer}
                onClose={handleCloseDrawer}
                header={{
                    title: transaction.TransactionType.title,
                    subtitle: `${transaction.TransactionType.way === "out" ? "-" : "+"} ${
                        transaction.amount ? formatMoneyToString({ amount: transaction.amount }) : null
                    }`,
                    image: { status: transaction?.isArchived ? "archived" : transaction.status },
                    optionalButtons: [
                        { icon: "document", onClick: handleDownloadReceipts, disabled: isLoadingReceipt },
                    ],
                }}
                body={<HistoryWalletDrawerBody handleOpenPasswordDialog={setActionDialog} walletId={walletId} />}
            />
            <PasswordValidationModal
                open={!!actionDialog && actionDialog.action !== "receipts"}
                setOpen={handleCloseDialog}
                handleOnSubmit={handleConfirm}
                error={passwordModalError}
            />
        </TransactionContext.Provider>
    ) : null;
}
