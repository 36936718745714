import { Icon } from "../Icons/Icon";
import { IconListType } from "../Icons/IconList";
import { Typography } from "../Typography/Typography";

import { SyntheticEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import "./styles.scss";

export type ToggleSwitchOption = { icon: IconListType; label: string };

export type ToggleSwitchProps = {
    firstOption: ToggleSwitchOption;
    secondOption: ToggleSwitchOption;
    selected: ToggleSwitchOption;
    onChange: (optionName: ToggleSwitchOption) => void;
    disabled?: boolean;
};

export const ToggleSwitch = ({ firstOption, secondOption, onChange, selected, disabled }: ToggleSwitchProps) => {
    const { t } = useTranslation();
    const [state, setState] = useState(selected || firstOption);

    useEffect(() => {
        setState(selected);
    }, [selected]);

    const setToggle = (e: SyntheticEvent) => {
        e.preventDefault();
        if (disabled) return;

        if (state === firstOption) {
            setState(secondOption);
            onChange(secondOption);
        } else {
            setState(firstOption);
            onChange(firstOption);
        }
    };

    return (
        <div
            onClick={setToggle}
            className={`toggler-wrapper ${disabled ? "disabled" : ""}`}
            data-testid='toggle-switch'
        >
            <div className={`toggler-inner ${state === secondOption ? "active" : ""}`} data-testid='toggler-inner'>
                <div className='toggler-item'>
                    <Icon size='sm' name={firstOption.icon} />
                    <Typography message={t(firstOption.label)} className='fw-bold' />
                </div>
                <div className='toggler-item'>
                    <Icon size='sm' name={secondOption.icon} />
                    <Typography message={t(secondOption.label)} className='fw-bold' />
                </div>
            </div>
        </div>
    );
};
