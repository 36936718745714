import { useLocation } from "react-router-dom";
import { BillingBiller } from "src/services/billing/biller/type";
import { retrieveNumberFromPattern } from "src/shared/utils/retrieveNumberFromPattern";

import { Dispatch, SetStateAction, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    biller?: BillingBiller;
    fields: { [key: string]: string };
    setFieldValues: Dispatch<
        SetStateAction<{
            [field: string]: any;
        }>
    >;
    errors: { message?: string; invoiceReference?: string; payerReference?: string };
};

export const BillingPayBillPaymentFields = ({ biller, fields, errors, setFieldValues }: Props) => {
    const { t } = useTranslation();
    const providedState = useLocation().state as { [key: string]: string };

    const handleSetField = (fieldName: string) => (value: string) => {
        setFieldValues({ ...fields, [fieldName]: value });
    };

    useEffect(() => {
        const newFields = Object.keys(biller?.billerFields ?? {}).reduce((res, field) => {
            return { ...res, [field]: providedState?.[field] ?? null };
        }, {});
        setFieldValues(newFields);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [biller?.billerFields]);

    return (
        <>
            {biller?.billerFields
                ? Object.entries(biller.billerFields).map(([name, data], index) => {
                      const maximumLength = retrieveNumberFromPattern(data.patern) ?? undefined;
                      return data.display ? (
                          <Input
                              autoFocus={index === 0}
                              key={`input-${name}`}
                              placeholder={t("CreateBeneficiaryAddForm.a-completer")}
                              label={data.label}
                              value={fields[name] ?? ""}
                              onChange={handleSetField(name)}
                              maxLength={maximumLength}
                              hint={
                                  maximumLength
                                      ? t<string>("Bills.pattern-maximum-length", {
                                            maximumLength,
                                        })
                                      : ""
                              }
                              underMessage={t<string>(errors?.[name as keyof Props["errors"]] ?? "")}
                              variant={errors?.[name as keyof Props["errors"]] ? "error" : "primary"}
                          />
                      ) : null;
                  })
                : null}
            {errors.message && (
                <div className='d-flex align-items-center mt-2'>
                    <Icon size='lg' color='error' name='x-circle-filled' />
                    <Typography className='color-error-500 fw-bold ml-2' message={t(errors.message)} />
                </div>
            )}
        </>
    );
};
