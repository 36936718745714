import { ExportFormSelection } from "../shared/FormSelection";
import { ExportTemplateRegularCreation } from "./Regular";
import { ScheduledWalletTemplate } from "./ScheduledWalletTemplate";
import { patchExportTemplate, patchExportTemplateStatus, sendExportTemplate } from "src/services/export/operations";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import {
    BankStatementExportTemplate,
    ExportTemplate,
    ExportTemplateFormType,
    RegularExportTemplate,
    RegularTemplateFormData,
    ScheduledWalletFormData,
} from "src/shared/models/Export";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { IconListType } from "src/shared/atoms/Icons/IconList";

import "./styles.scss";

type CreateBodyDrawerProps = {
    isOpen: boolean;
    handleOpenCreateDrawer: () => void;
    template?: ExportTemplate;
    isDuplicate: boolean;
    refreshList?: (display: ExportTemplateFormType) => void;
    preSelectedFormType?: ExportTemplateFormType;
    preSelectedWalletIds?: number[] | number;
};

export const CreateTemplateBodyDrawer = ({
    isOpen,
    handleOpenCreateDrawer,
    template,
    isDuplicate,
    refreshList,
    preSelectedFormType,
    preSelectedWalletIds,
}: CreateBodyDrawerProps) => {
    const { t } = useTranslation();
    const [selectedFormType, setSelectedFormType] = useState<ExportTemplateFormType>();
    const [formData, setFormData] = useState({});
    const [hidden, setHidden] = useState(false);
    const [isOpenPasswordModal, setIsOpenPasswordModal] = useState<boolean>(false);
    const [createTemplateError, setCreateTemplateError] = useState();
    const [createTemplateSuccess, setCreateTemplateSuccess] = useState(false);
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [isEditingForm, setIsEditingForm] = useState<boolean>();
    const [isDuplicatingForm, setIsDuplicatingForm] = useState<boolean>(isDuplicate);

    useEffect(() => {
        if (isOpen) {
            setSelectedFormType(undefined);
            setIsEditingForm(false);
            setIsDuplicatingForm(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (preSelectedFormType) {
            handleSelectFormType(preSelectedFormType);
        }
    }, [preSelectedFormType]);

    const handleTogglePasswordModal = () => {
        setIsOpenPasswordModal(!isOpenPasswordModal);
        setHidden(!hidden);
    };

    const handleFormDataChange = (newData: RegularTemplateFormData) => {
        setFormData({ ...formData, ...newData });
    };

    const handleSelectFormType = (formType?: ExportTemplateFormType) => {
        setSelectedFormType(formType);
        setFormData({ type: formType });
    };

    const handleConfirmPasswordModal = async ({
        password,
        twoFactor,
    }: {
        password: string;
        twoFactor: string | null;
    }) => {
        setCreateTemplateError(undefined);
        setCreateTemplateSuccess(false);

        const templateData = {
            data: formData as RegularTemplateFormData | ScheduledWalletFormData,
            password: password,
            twoFactor: twoFactor,
        };

        try {
            if (template && !isDuplicate) {
                await patchExportTemplate(formData, template.id).then(() => {
                    setCreateTemplateSuccess(true);
                    handleOpenCreateDrawer();
                });
            } else {
                await sendExportTemplate(templateData).then(() => {
                    setCreateTemplateSuccess(true);
                    handleOpenCreateDrawer();
                });
            }
        } catch (e: any) {
            setCreateTemplateError(e.message);
        } finally {
            if (refreshList) {
                refreshList(selectedFormType ?? "regular");
            }
        }
    };

    useEffect(() => {
        if (template) {
            setIsEditingForm(true);
            setSelectedFormType(template.type);
            const editFormData = {
                name: template.name,
                walletIds: template.walletIds,
                transactionStatus: template.transactionStatus,
                transactionTypes: template.transactionTypes,
                columns: template.columns,
                type: template.type,
            };

            setFormData(editFormData);
        }
    }, [template]);

    useEffect(() => {
        if (isDuplicate) {
            setIsDuplicatingForm(isDuplicate);
        }
    }, [isDuplicate]);

    const handleCancelButton = () => {
        handleOpenCreateDrawer();
    };

    const renderDrawerHeader = () => {
        let title = t("CommonUse.add", { entity: t("Export.a-export-template") });
        let image: IconListType = "wallet";

        switch (selectedFormType) {
            case "bank_statement":
                title = t("Export.scheduled-bank-statement");
                image = "bank";
                break;
            case "recurrent":
                title = t("Export.scheduled-template");
                image = "clock";
                break;
            case "regular":
                title = t("Export.export-template");
                image = "document";
                break;
        }

        return { title, image };
    };

    const reactivateTemplateStatus = async () => {
        await patchExportTemplateStatus(template as ExportTemplate).then(() => {
            if (refreshList) {
                refreshList("recurrent");
            }
            handleOpenCreateDrawer();
        });
    };

    const formValidation = (validated: boolean) => {
        setIsFormValidated(!validated);
    };

    const renderFooterButtonLabel = () => {
        if (isDuplicatingForm) {
            return t("Export.duplicate-template");
        } else if (isEditingForm) {
            return t("Export.edit-template");
        } else {
            return t("Export.create-template");
        }
    };

    const renderPasswordHeaderTitle = () => {
        let title;
        if (isDuplicatingForm) {
            title = "Export.duplicate-template";
        } else if (isEditingForm) {
            title = "CommonUse.modify-entity";
        } else {
            title = "CommonUse.add";
        }

        let type;
        if (selectedFormType === "regular") {
            type = "Export.a-export-template";
        } else if (selectedFormType === "recurrent") {
            type = "Export.a-scheduled-export-template";
        } else {
            type = "Export.a-bank-statement-export-template";
        }

        return t(title, { entity: t(type) });
    };

    const renderSuccessContent = () => {
        if (isDuplicatingForm) {
            return t(`Export.export-${selectedFormType}-duplicate-success`).toString();
        } else if (isEditingForm) {
            return t(`Export.export-${selectedFormType}-edit-success`).toString();
        } else {
            return t(`Export.export-${selectedFormType}-creation-success`).toString();
        }
    };

    return (
        <>
            <Drawer
                isHidden={hidden}
                isOpen={isOpen}
                onClose={handleOpenCreateDrawer}
                header={renderDrawerHeader()}
                body={
                    <div className='create-export-template-drawer-body-container'>
                        {template?.status === "disabled" && (
                            <Alert
                                message={t("Export.deactivate-export-dec")}
                                icon='x-circle'
                                color='warning'
                                buttons={{
                                    label: t("Export.reactivate"),
                                    onClick: reactivateTemplateStatus,
                                    icon: "refresh",
                                    color: "warning",
                                }}
                            />
                        )}
                        {!template && (
                            <ExportFormSelection
                                selectedFormType={selectedFormType}
                                handleSelectedTemplateFormType={handleSelectFormType}
                                mode='template'
                            />
                        )}
                        {selectedFormType === "regular" && (
                            <ExportTemplateRegularCreation
                                handleFormDataChange={handleFormDataChange}
                                template={template as RegularExportTemplate | undefined}
                                sendFormValidation={formValidation}
                                preSelectedWalletIds={preSelectedWalletIds}
                            />
                        )}
                        {selectedFormType === "recurrent" && (
                            <ExportTemplateRegularCreation
                                isScheduled
                                handleFormDataChange={handleFormDataChange}
                                template={template as RegularExportTemplate | undefined}
                                sendFormValidation={formValidation}
                                preSelectedWalletIds={preSelectedWalletIds}
                            />
                        )}
                        {selectedFormType === "bank_statement" && (
                            <ScheduledWalletTemplate
                                handleFormDataChange={handleFormDataChange}
                                template={template as BankStatementExportTemplate}
                                sendFormValidation={formValidation}
                                preSelectedWalletIds={preSelectedWalletIds}
                            />
                        )}
                    </div>
                }
                footer={{
                    primaryButton: {
                        label: renderFooterButtonLabel(),
                        onClick: handleTogglePasswordModal,
                        disabled: isFormValidated,
                    },
                    secondaryButton: {
                        label: t("CommonUse.cancel"),
                        onClick: handleCancelButton,
                    },
                }}
            />
            <PasswordValidationModal
                header={{
                    title: renderPasswordHeaderTitle(),
                    icon: "arrow-down-on-square",
                }}
                successContent={{
                    image: require("src/shared/images/user/success.png"),
                    text: renderSuccessContent(),
                }}
                asyncFromParent
                open={isOpenPasswordModal}
                error={createTemplateError}
                succeeded={createTemplateSuccess}
                setOpen={handleTogglePasswordModal}
                handleOnSubmit={handleConfirmPasswordModal}
            />
        </>
    );
};
